/* ***************** Deafult Start  ******************** */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@font-face {
  font-family: "SF Pro Display";
  src: url("../webfonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("../webfonts/SFProDisplay-Medium.woff") format("woff"),
    url("../webfonts/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../webfonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../webfonts/SFProDisplay-Regular.woff") format("woff"),
    url("../webfonts/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../webfonts/SFProDisplay-LightItalic.woff2") format("woff2"),
    url("../webfonts/SFProDisplay-LightItalic.woff") format("woff"),
    url("../webfonts/SFProDisplay-LightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../webfonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../webfonts/SFProDisplay-Bold.woff") format("woff"),
    url("../webfonts/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../webfonts/SFProDisplay-SemiboldItalic.woff2") format("woff2"),
    url("../webfonts/SFProDisplay-SemiboldItalic.woff") format("woff"),
    url("../webfonts/SFProDisplay-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../webfonts/SFProDisplay-UltralightItalic.woff2") format("woff2"),
    url("../webfonts/SFProDisplay-UltralightItalic.woff") format("woff"),
    url("../webfonts/SFProDisplay-UltralightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../webfonts/SFProDisplay-HeavyItalic.woff2") format("woff2"),
    url("../webfonts/SFProDisplay-HeavyItalic.woff") format("woff"),
    url("../webfonts/SFProDisplay-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../webfonts/SFProDisplay-ThinItalic.woff2") format("woff2"),
    url("../webfonts/SFProDisplay-ThinItalic.woff") format("woff"),
    url("../webfonts/SFProDisplay-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../webfonts/SFProDisplay-BlackItalic.woff2") format("woff2"),
    url("../webfonts/SFProDisplay-BlackItalic.woff") format("woff"),
    url("../webfonts/SFProDisplay-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Klavika Basic";
  src: url("../webfonts/KlavikaBasic-Regular.woff2") format("woff2"),
    url("../webfonts/KlavikaBasic-Regular.woff") format("woff"),
    url("../webfonts/KlavikaBasic-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Klavika Basic";
  src: url("../webfonts/KlavikaBasic-Regular.woff2") format("woff2"),
    url("../webfonts/KlavikaBasic-Regular.woff") format("woff"),
    url("../webfonts/KlavikaBasic-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../webfonts/SegoeUI.woff2") format("woff2"),
    url("../webfonts/SegoeUI.woff") format("woff"),
    url("../webfonts/SegoeUI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../webfonts/SegoeUI-Italic.woff2") format("woff2"),
    url("../webfonts/SegoeUI-Italic.woff") format("woff"),
    url("../webfonts/SegoeUI-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../webfonts/SegoeUI-Bold.woff2") format("woff2"),
    url("../webfonts/SegoeUI-Bold.woff") format("woff"),
    url("../webfonts/SegoeUI-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../webfonts/SegoeUI-BoldItalic.woff2") format("woff2"),
    url("../webfonts/SegoeUI-BoldItalic.woff") format("woff"),
    url("../webfonts/SegoeUI-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

.po-site {
  font-family: "Open Sans", sans-serif;
}

a {
  transition: all ease-in-out 0.3s;
}

.btn {
  border-width: 2px;
  border-radius: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 10px 15px;
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus,
.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show>.btn-warning.dropdown-toggle:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-check:checked+.btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus,
.btn:focus {
  box-shadow: none;
}

.btn-warning {
  color: #ffffff;
  background-color: #ffbb00;
  border-color: #ffbb00;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus,
.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle,
.btn-warning:hover {
  color: #ffffff;
}

.btn-outline-warning {
  border-color: #ffbb00;
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active,
.btn-outline-warning:hover {
  color: #ffffff;
}

.btn-outline-primary {
  color: #008ef9;
  border-color: #008ef9;
}

.btn-outline-danger {
  color: #ea4335;
  border-color: #ea4335;
}

.btn-outline-secondary {
  color: #646667;
  border-color: #646667;
}

.btn-outline-primary:active svg path,
.btn-outline-primary:hover svg path,
.btn-outline-secondary:active svg path,
.btn-outline-secondary:hover svg path,
.btn-outline-danger:active svg path,
.btn-outline-danger:hover svg path {
  fill: #ffffff;
}

.form-control {
  border: 1px solid #acb4ba;
  border-radius: 12px;
  padding: 14px 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #646667;
}

.form-control::placeholder {
  color: #646667;
}

.form-control:focus {
  color: #646667;
  box-shadow: none;
}

/* ***************** Deafult End  ******************** */

/* ***************** Header Start  ******************** */

.po-site-header {
  position: relative;
  z-index: 100;
  background-color: #fff;
  padding: 15.5px 0;
  border-bottom: 1px solid #008ef9;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-logo {
  width: 117px;
  max-width: 100%;
}

.header-inputs {
  justify-content: space-between;
  border: 0.5px solid #acb4ba;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  padding: 7px 22px;
  display: flex;
  align-items: center;
  width: 323px;
  max-width: 100%;
  position: relative;
}

.header-inputs .form-control {
  border: none;
  padding: 0 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #292a2c;
  border-radius: 0;
  width: 80px;
  position: relative;
}

.header-inputs::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 38%;
  transform: translatey(-50%);
  width: 0.5px;
  height: 25px;
  background-color: #acb4ba;
}

.header-inputs .form-control::placeholder {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #292a2c;
}

.header-btn {
  padding: 0;
  width: 32px;
  height: 32px;
  max-width: 100%;
  border-radius: 50%;
}

.header-btn img {
  width: 14px;
}

.header-dropdown {
  display: flex;
  align-items: center;
}

.dropdown {
  border: 0.5px solid #acb4ba;
  border-radius: 50px;
}

.header-dropdown-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #292a2c;
  margin-right: 45px;
}

.header-dropdown-btn img:last-child {
  width: 32px;
  border-radius: 50%;
}

.header-dropdown-btn img:first-child {
  margin-right: 8px;
}

.header-dropdown-btn {
  padding: 3px 5px 3px 5.5px;
}

.dropdown-menu {
  right: 0;
  box-shadow: 0px 1px 3px 0.5px rgba(0, 0, 0, 0.1);
}

/* ***************** Header End  ******************** */

/* ***************** Login/Sigup Page Start  ******************** */

.po-login-section {
  position: relative;
}

.po-login-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.po-login-img img {
  width: 100%;
}

.login-form-area {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  height: 100vh;
  overflow: auto;
}

.login-img img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.login-body-content {
  background-color: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 70px 75px 68px;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
}

.login-logo {
  width: 156px;
  max-width: 100%;
  margin: 0 auto 50px;
}

.login-input label {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #17146b;
  margin-bottom: 10px;
  font-family: "PT Sans", sans-serif;
}

.login-select-country .login-select-btn {
  background-position: left 51px center;
  padding: 12.5px 0 12.5px 16px;
  background-size: 10px;
  border-radius: 0;
  border: none;
  border-bottom: 0.5px solid #959da3;
}

.login-select-country .login-select-btn:hover {
  background-color: #fff;
  box-shadow: none;
  border: none;
  border-bottom: 0.5px solid #959da3;
}

.login-select-country .login-select-item img,
.login-select-country .login-select-btn img {
  width: 25px;
}

.login-input {
  margin-bottom: 5px;
  background: #ffffff;
}

.login-btn .btn {
  background-color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffbb00;
  padding: 13px 125.5px;
  border: 1px solid #ffbb00;
}

.po-login-back-link {
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  display: inline-flex;
  align-items: center;
  color: #17146b;
  text-decoration: none;
  margin-bottom: 20px;
}

.po-login-back-link:hover {
  color: #17146b;
  opacity: 0.8;
}

.po-login-back-link-icon {
  margin-right: 16px;
}

.po-login-content__inner {
  padding: 72px 83px 60px;
  background: #ffffff;
  border-radius: 12px;
}

.po-login-logo {
  text-align: center;
  margin-bottom: 27px;
}

.po-login-sigup-btn {
  margin-bottom: 80px;
}

.po-login-btn-label {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #959da3;
  display: block;
  margin-bottom: 15px;
}

.po-login-btn .btn:not(:last-child) {
  margin-bottom: 15px;
}

.po-login-sigup-social-btn .btn {
  font-weight: 400;
}

.po-btn-icon {
  margin-right: 15px;
}

.login-select-box {
  width: 105px;
  max-width: 100%;
}

.login-select-country {
  display: flex;
  align-items: center;
}

.login-input .login-country-input .form-control {
  padding: 14px 14px 15px 4px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
}

.login-input .login-country-input .form-control::placeholder {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
}

.login-select-list .login-select-item li {
  padding: 10px;
}

.login-select-box li span {
  font-weight: 600;
}

.login-select-list .login-select-item li span {
  margin-left: 20px;
}

.login-country-input {
  width: calc(100% - 105px);
}

/* ***************** Login/Sigup Page End  ******************** */

/* ***************** Login Form Page Start  ******************** */

.login-forgot {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #646667;
  margin-top: 20px;
  margin-bottom: 0;
}

.po-login-forgot-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #008ef9;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}

.login-other-option {
  margin-top: 60px;
  text-align: center;
}

.po-login-forgot-link:hover {
  color: #008ef9;
  opacity: 0.8;
}

.login-other-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #959da3;
}

.login-option-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1877f1;
  padding: 13px 25px;
  border: 1px solid #1877f1;
  border-radius: 50px;
  text-decoration: none;
  position: relative;
}

.login-option-item {
  margin-bottom: 15px;
}

.login-option-item:last-child {
  margin-bottom: 0;
}

.login-option-list .login-option-item:nth-child(1) .login-option-link::before {
  content: "\f39e";
  position: absolute;
  left: 25px;
  font-weight: 400;
  font-family: "Font Awesome 6 Brands";
}

.login-option-list {
  margin: 15px auto 0;
  width: 300px;
  max-width: 100%;
}

.login-option-link:hover {
  opacity: 0.6;
}

.login-option-list .login-option-item:nth-child(2) .login-option-link::before {
  content: "\f1a0";
  position: absolute;
  left: 25px;
  font-weight: 400;
  font-family: "Font Awesome 6 Brands";
}

.login-option-list .login-option-item:nth-child(3) .login-option-link::before {
  content: "\f0e0";
  position: absolute;
  left: 25px;
  font-weight: 400;
  font-family: "Font Awesome 6 free";
}

.login-option-list .login-option-item:nth-child(2) .login-option-link {
  color: #ea4335;
  border: 1px solid #ea4335;
}

.login-option-list .login-option-item:nth-child(3) .login-option-link {
  color: #646667;
  border: 1px solid #646667;
}

.login-input .form-control {
  border: none;
  border-bottom: 0.5px solid #959da3;
  border-radius: 0;
  padding: 14px 14px 15px 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #959da3;
}

.login-input .form-control::placeholder {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #959da3;
}

.login-email-body {
  padding: 100px 75px;
}

.login-email-input {
  position: relative;
}

.login-email-cancel {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

/* Login Modal Start */

.login-modal .login-modal-content {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.login-modal-dialog {
  max-width: 402px;
}

.login-modal-body {
  padding: 0px 40px 50px;
}

.login-modal-close {
  height: 50px;
  width: 100%;
  border: none;
}

.login-modal-title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #17146b;
}

.login-modal-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-modal-input .form-control {
  border: none;
  border-bottom: 2px solid #acb4ba;
  border-radius: 0;
  width: 65px;
  max-width: 100%;
  height: 58px;
  padding: 10px 15px;
}

.login-modal-input {
  margin: 12px 0 30px;
}

.login-otp-resend a {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #959da3;
  display: block;
  text-decoration: none;
}

.login-otp-resend a:last-child {
  margin-bottom: 0;
  color: #008ef9;
}

.login-modal-btn .btn {
  font-size: 16px;
  line-height: 22px;
  padding: 12px 78.7px;
  margin-top: 20px;
}

/* Login Modal End */

/* Gmail Modal Start */

.gmail-modal-dialog {
  max-width: 450px;
  margin: 1.75rem auto;
}

.gmail-modal-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #646667;
  display: flex;
  align-items: center;
}

.gmail-modal-title img {
  margin-right: 20px;
}

.gmail-modal-header {
  padding: 14.9px 24px;
}

.gmail-modal-body {
  padding: 40px 50px 30px;
}

.gmail-modal-plus {
  margin-bottom: 30px;
}

.gmail-modal-body-title {
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  color: #000000;
  text-align: center;
  margin-bottom: 10px;
}

.gmail-modal-body-subtitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  text-align: center;
  margin-bottom: 22px;
}

.gmail-modal-body-subtitle a {
  text-decoration: none;
  color: #3073e8;
  font-weight: 600;
}

.gmail-modal-choose-account a {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px 0;
  border-bottom: 0.5px solid #acb4ba;
}

.gmail-account-img {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  max-width: 100%;
}

.gmail-account-img img {
  border-radius: 50%;
  width: 100%;
}

.gmail-account-id h5 {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
  margin-bottom: 0;
}

.gmail-account-id span {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #646667;
}

/* Gmail Modal End */

/* ***************** Login Form Page End  ******************** */

/******** Forgot Password Section Start *******/

.forgot-pass-content {
  border: 0.5px solid #acb4ba;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 570px;
  margin: 220px auto 0;
}

.forgot-pass-heading {
  border-bottom: 0.5px solid #acb4ba;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  padding: 11.2px 30px;
}

.forgot-pass-heading h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #17146b;
  margin-bottom: 0;
  width: 100%;
}

.forgot-pass-body {
  padding: 36px 35px 48px;
}

.forgot-pass-discription {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 20px;
}

.forgot-pass-option {
  margin-bottom: 20px;
}

.forgot-pass-link {
  display: flex;
  align-items: center;
}

.forgot-pass-option label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
  border-radius: 10px;
  padding: 25px 34px 25px 46px;
}

.forgot-pass-type h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 0;
}

.forgot-pass-img {
  margin-right: 30px;
  width: 50px;
  height: 50px;
}

.forgot-pass-option input {
  width: 25px;
  height: 25px;
}

.forgot-pass-type h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #959da3;
  margin-bottom: 0;
}

.forgot-pass-btn {
  font-size: 16px;
  line-height: 22px;
  padding: 12px 15px;
  margin-top: 10px;
}

/* Forgot Modal Start */

.forgot-modal-body {
  padding: 0px 47px 45px;
}

.forgot-modal-dialog {
  max-width: 400px;
}

.forgot-modal-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #17146b;
  margin-bottom: 10px;
  font-family: "PT Sans", sans-serif;
}

.forgot-modal-discription {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #292a2c;
  max-width: 292px;
  margin: 0 auto;
}

.forgot-modal-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 10px;
}

.forgot-modal-input input {
  background-color: #f9fafb;
  border-radius: 10px;
  border: none;
  width: 70px;
  height: 70px;
  margin-right: 10px;
  padding: 10px 30px;
}

.forgot-pass-email .form-control {
  border: 0.5px solid #959da3;
  border-radius: 10px;
  padding: 14px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #959da3;
}

.forgot-pass-email .form-control::placeholder {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #959da3;
}

.forgot-pass-email {
  margin-bottom: 50px;
}

.forgot-pass-new-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  color: #17146b;
  margin-bottom: 20px;
  font-family: "PT Sans", sans-serif;
}

.forgot-pass-pattern {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #959da3;
  margin-bottom: 6px;
}

.forgot-pass-pattern i {
  color: #d9d9d9;
  margin-right: 15px;
}

.forgot-pass-new-input label {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #17146b;
  margin-bottom: 10px;
  font-family: "PT Sans", sans-serif;
}

.forgot-pass-new-input {
  margin-bottom: 30px;
}

.new-password {
  margin-top: 50px;
}

.forgot-new-password {
  position: relative;
}

.forgot-pass-cancel {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  max-width: 100%;
}

.forgot-pass-match i {
  color: #4cb90b;
  font-size: 20px;
  margin-right: 8px;
}

.forgot-pass-match {
  margin: 10px 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #4cb90b;
  display: flex;
  align-items: center;
}

/* Forgot Modal End */

/* Password Modal Start */

.password-dialog {
  max-width: 400px;
}

.password-modal-body {
  padding: 63px 60px;
}

.password-modal-title {
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
  color: #17146b;
  margin: 25px 0 20px;
  font-family: "PT Sans", sans-serif;
}

.password-modal-discription {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #959da3;
  margin-bottom: 0;
}

/* Password Modal End */

.forgot-pass-not-match {
  color: #ff5b50;
}

.forgot-pass-not-match i {
  color: #ff5b50;
}

/******** Forgot Password Section End *******/

/******** Co Login Section Start ********/

.co-login-form {
  width: 580px;
  margin: 0 auto;
  padding: 27px 0;
  height: 100%;
  padding: 20px 0;
  height: 100vh;
  overflow: auto;
}

.co-login-img {
  text-align: end;
  margin: 0;
}

.co-login-form-content {
  padding-top: 240px;
}

.co-login-img img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.co-login-title {
  font-weight: 700;
  font-size: 45px;
  line-height: 55px;
  color: #17146b;
  margin-bottom: 30px;
  font-family: "PT Sans", sans-serif;
}

.co-login-discription {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #292a2c;
  margin-bottom: 50px;
}

.co-login-select-country {
  border: 0.5px solid #959da3;
  border-radius: 10px;
  margin-right: 20px;
  width: calc(100% - 220px);
}

.co-login-select-country .login-select-btn {
  border-radius: 10px;
}

.co-login-input label {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 20px;
}

.co-login-input-box {
  display: flex;
  align-items: center;
}

.co-login-sending-discription {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
  margin: 21px 0 30px;
}

.co-login-btn {
  width: 200px;
  max-width: 100%;
}

.co-login-btn .btn {
  font-size: 16px;
  line-height: 22px;
  padding: 12px;
}

.co-login-app-store img:first-child {
  margin-right: 20px;
}

.co-login-select-country .login-country-input .form-control {
  padding-left: 10px;
}

/******** Co Login Section End ********/

/********* Login Facebook Sectio Start **********/

.login-facebook-header {
  background-color: #4267b2;
  padding: 12.5px 0;
}

.login-facebook-header i {
  width: 25px;
  height: 25px;
  background-color: #fff;
  color: #4267b2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.login-facebook-header h5 {
  margin-left: 362px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-family: "Klavika Basic";
}

.login-facebook-content {
  padding: 195px 0 245px;
  width: 343px;
  margin: 0 auto;
  text-align: center;
}

.login-facebook-logo {
  margin-bottom: 70px;
}

.login-facebook-discription {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #292a2c;
  max-width: 260px;
  margin: 0 auto 20px;
}

.login-facebook-post {
  font-size: 13px;
  color: #959da3;
  margin: 30px auto;
}

.btn-primary {
  background-color: #1a77f2;
  color: #ffffff;
}

.login-facebook-btn .btn {
  font-family: "Segoe UI";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  display: block;
  padding: 11px;
}

.login-facebook-cancel {
  color: #000000;
  margin-top: 15px;
  background-color: #e6eef8;
}

.login-facebook-btn {
  margin-bottom: 50px;
}

.login-facebook-policy {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #646667;
}

.login-facebook-policy a {
  text-decoration: none;
  color: #1a77f2;
  font-weight: 600;
}

.gmail-footer-modal {
  padding: 0 47px 53px;
}

/********* Login Facebook Sectio End **********/

/******* Signup Section Start *******/

.signup-body-content {
  padding: 114px 75px;
}

.signup-logo {
  margin: 0 auto 20px;
}

.signup-body-content .signup-body-title {
  line-height: 50px;
  margin-bottom: 0;
}

.signup-btn {
  margin: 50px 0 20px;
}

.signup-btn .btn:first-child {
  background-color: #ffbb00;
  color: #fff;
  margin-bottom: 15px;
  padding: 13px 119.2px;
}

.signup-policy {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #292a2c;
  max-width: 260px;
  margin: 0 auto;
}

.signup-policy a {
  color: #008ef9;
  font-weight: 600;
  text-decoration: none;
}

.payment-method-link .signup-payment-card-img {
  width: 40px;
  margin-right: 23px;
}

.signup-payment-promo.payment-method-promo a:last-child {
  font-weight: 600;
  color: #292a2c;
}

.information-form-box .signup-card-name h5 {
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
}

/******* Signup Section End *******/

/****** Notification Section Start *******/

.notification-content {
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
  padding: 80px 0;
}

.notification-title {
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: #17146b;
  margin-bottom: 50px;
  font-family: "PT Sans", sans-serif;
}

.notification-item {
  display: flex;
  align-items: start;
  padding: 21px 0;
  border-bottom: 0.5px solid #acb4ba;
}

.notification-item:last-child {
  border-bottom: 0px solid #acb4ba;
}

.notification-icon {
  background-color: #dcffc5;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}

.notification-details-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
  margin-bottom: 3px;
}

.notification-details {
  margin-right: 65px;
  width: calc(100% - 168px);
}

.notification-item.active .notification-details-title {
  font-weight: 700;
}

.notification-item.active .notification-discription {
  color: #292a2c;
}

.notification-discription {
  margin-bottom: 3px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #646667;
}

.notification-time {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #646667;
  margin-bottom: 0;
}

.notification-remove-icon {
  width: 25px;
  height: 25px;
  max-width: 100%;
}

.notification-list .notification-item:nth-child(2) .notification-icon {
  background-color: #d5edff;
}

.notification-list .notification-item:nth-child(3) .notification-icon {
  background-color: #fff2b0;
}

.notification-list .notification-item:nth-child(7) .notification-icon {
  background-color: #fff2b0;
}

.notification-list .notification-item:nth-child(8) .notification-icon {
  background-color: #d5edff;
}

/****** Notification Section End *******/

/******* Profile Sharing Section Start *******/

.profile-account-header .container-fluid {
  padding: 0 30px;
}

.privacy-sharing-tab-body .privacy-activity-title {
  margin-bottom: 22px;
}

.privacy-activity-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 5px;
}

.form-switch .form-check-input:checked {
  background-color: #d5edff;
  border-color: #d5edff;
}

.form-switch .form-check-input:checked {
  background-position: right center;
  /* background-image: url("../images/switch_circle.png"); */
  background-size: 27px;
}

.form-switch .form-check-input {
  background-size: 33px;
}

.form-switch .form-check-input {
  height: 27px;
  width: 52px;
  border: none;
  background-color: #d5edff;
  border-color: #d5edff;
}

.privacy-activity-content {
  border-bottom: 0.5px solid #acb4ba;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.privacy-activity-content p a {
  color: #292a2c;
  font-weight: 700;
  display: inherit;
}

.privacy-sharing-tab-body .privacy-service-discription {
  line-height: 25px;
  color: #292a2c;
}

.po-sharing-section {
  background-color: #f9fafb;
}

.po-sharing-sidebar {
  background-color: #ffffff;
  box-shadow: 5px 0px 18px rgba(0, 0, 0, 0.05);
  padding: 50px 30px 20px;
  width: 450px;
  height: calc(100vh - 76px);
  max-width: 100%;
  overflow-y: scroll;
}

.po-sharing-sidebar::-webkit-scrollbar {
  display: none;
}

.po-sharing-sidebar-icon {
  font-size: 30px;
  position: absolute;
  left: -1px;
  top: 6px;
  z-index: 1;
}

.po-sharing-referrals {
  padding: 43px 0 30px;
  border-bottom: 1px solid #acb4ba;
}

.po-sharing-form-content {
  height: calc(100vh - 76px);
  margin: 0 auto;
  padding: 0 50px 50px;
  overflow-y: scroll;
}

.po-sharing-form-content::-webkit-scrollbar {
  display: none;
}

.po-sharing-form-box {
  background: #ffffff;
  border: 0.5px solid #acb4ba;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 45px 47px 57px;
  width: 595px;
  max-width: 100%;
  margin: 50px auto 0;
}

.po-sharing-content {
  display: flex;
  height: calc(100vh - 76px);
  /* align-items: center; */
}

.po-sharing-profile {
  display: flex;
  align-items: center;
  margin-bottom: 46px;
}

.po-sharing-profile-img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  max-width: 100%;
}

.po-sharing-profile-img img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.po-sharing-profile-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  display: flex;
  align-items: center;
  color: #17146b;
  margin-bottom: 0;
  font-family: "PT Sans", sans-serif;
}

.po-sharing-profile-view {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-decoration-line: underline;
  color: #17146b;
}

.po-sharring-setting-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 0;
}

.po-sharing-setting-list {
  list-style: none;
  padding: 20px 0 30px;
  margin-bottom: 0;
}

.po-sharing-setting-link img {
  margin-right: 20px;
}

.po-sharing-setting-link svg {
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
}

.po-sharing-setting-link.active svg path {
  fill: #000;
}

.po-sharing-setting-link:hover svg path {
  fill: #000;
  transition: all 0.3s ease-in-out;
}

.po-sharing-setting-link {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #646667;
  text-decoration: none;
  padding: 25px;
  border-radius: 12px;
}

.po-sharing-setting-link:hover {
  background-color: #f7f7f7;
  color: #292a2c;
}

.po-sharing-setting-link.active {
  background-color: #f7f7f7;
  font-weight: 700;
  color: #292a2c;
}

.po-sharing-moment {
  padding: 50px 0 30px;
  margin-bottom: 44px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #acb4ba;
}

.po-sharing-moment a img {
  width: 16px;
  margin-right: 24px;
}

.po-sharing-moment a {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #ffbb00;
  text-decoration: none;
  margin-top: 20px;
  padding: 25px 5px;
}

.po-sharing-form-input {
  margin-bottom: 30px;
}

.po-sharing-form-input label {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #646667;
  margin-bottom: 10px;
}

.po-sharing-form-input .form-control {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #acb4ba;
  padding: 11.5px 27px;
  border: 1px solid #acb4ba;
}

.po-sharing-form-input .form-control::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #acb4ba;
}

.label-none {
  opacity: 0;
}

.po-sharing-form-details {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.pos-sharing-form-details-title h6 {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 15px;
}

.pos-sharing-form-details-title p {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #646667;
  margin-bottom: 0;
}

.po-sharing-form-edit a {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  color: #292a2c;
}

.po-sharing-form-details {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 30px 0;
  margin-bottom: 30px;
  border-width: 0.5px 0;
  border-style: solid;
  border-color: #959da3;
}

/******* Profile Sharing Section End *******/

/******* View Profile Section Start *******/

.view-profile-section {
  padding: 80px 0 202px;
}

.view-profile-content {
  display: flex;
  align-items: start;
  padding: 50px 0;
  height: calc(100vh - 76px);
  overflow-y: scroll;
}

.view-profile-content::-webkit-scrollbar {
  display: none;
}

.view-profile-box {
  border: 0.5px solid #959da3;
  border-radius: 12px;
  padding: 42px 36px 30px 25px;
  margin: 0 80px 0 50px;
  width: 310px;
  max-width: 100%;
  box-shadow: 0px 0px 18px rgb(0 0 0 / 5%);
}

.view-profile-img {
  position: relative;
  width: 128px;
  height: 128px;
  margin: 0 auto 15px;
}

.view-profile-check {
  position: absolute;
  bottom: 0;
  right: 0;
}

.view-profile-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  display: block;
  color: #292a2c;
  margin-bottom: 30px;
}

.view-profile-confirmed {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 10px;
  margin-top: 30px;
}

.view-profile-confirmed-item i {
  margin-right: 9px;
}

.view-profile-button {
  margin: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.view-profile-confirmed-item {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 10px;
}

.view-profile-confirmed-item:last-child {
  margin-bottom: 0;
}

.view-profile-upload {
  margin-left: 75px;
  width: 100px;
  margin-top: 10px;
}

.view-profile-details-content {
  width: calc(100% - 500px);
  border: 0.5px solid #959da3;
  border-radius: 12px;
  padding: 45px 36px;
  max-width: 100%;
  box-shadow: 0px 0px 18px rgb(0 0 0 / 5%);
  margin-bottom: 20px;
}

.view-profile-details-title {
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: #17146b;
  margin-bottom: 5px;
  font-family: "PT Sans", sans-serif;
}

.view-profile-details-date {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #959da3;
  margin-bottom: 5px;
}

.view-profile-details-edit {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
}

.view-profile-about {
  margin-top: 40px;
}

.view-profile-address-item img {
  margin-right: 14px;
  width: 16px;
}

.view-profile-address {
  margin-top: 30px;
}

.view-profile-review-title img {
  margin-right: 14px;
}

.view-profile-review {
  margin: 40px 0;
  padding: 0 0 20px;
  border-width: 0.5px 0;
  border-style: solid;
  border-color: #acb4ba;
}

.view-profile-review-by {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
}

.view-profile-review-by:hover {
  color: #292a2c;
}

.view-profile-input {
  margin-bottom: 20px;
}

/******* View Profile Section End *******/

/****** Privacy Policy Section Start ******/

.policy-section {
  padding: 80px 0;
}

.breadcrumb-item::before {
  display: none;
}

.breadcrumb {
  margin-bottom: 10px;
  align-items: center;
}

.breadcrumb-item a {
  text-decoration: none;
}

.breadcrumb-item.active {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #646667;
}

.policy-rules-subtitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #646667;
  margin-bottom: 10px;
}

.policy-img {
  margin: 50px 0 80px;
}

.policy-img img {
  border-radius: 10px;
  height: 350px;
}

.policy-rules-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 30px;
}

.policy-rukes-discription {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 0;
}

.policy-rules-country {
  margin: 50px 0;
}

.policy-rules-country li {
  margin-bottom: 15px;
}

.policy-rules-country h5 {
  margin-bottom: 20px;
}

.policy-rules-country li a {
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 18px;
}

.policy-rules-country li a:hover {
  color: #000000;
  opacity: 0.9;
}

.policy-contact h5 {
  margin-bottom: 10px;
}

.policy-contact {
  max-width: 325px;
  margin: 0 auto;
  padding-top: 126px;
}

.policy-contact-btn {
  border: 1px solid #ffbb00;
  border-radius: 50px;
  padding: 13px 56px;
  margin: 10px 0 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffbb00;
}

.policy-contact-btn:hover {
  color: #ffbb00;
  opacity: 0.9;
}

.policy-contact-feedback {
  font-weight: 600;
  color: #000000;
}

.policy-contact-feedback:hover {
  color: #000000;
}

.policy-rules.terms-services {
  width: 600px;
  max-width: 100%;
}

.terms-discription {
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  padding: 24px 66px 24px 24px;
  margin: 50px 0;
  display: flex;
}

.terms-rules-discription {
  color: #646667;
  margin-bottom: 15px;
}

.terms-rules-discription:last-child {
  margin-bottom: 0;
}

.terms-rules-discription a {
  font-weight: 600;
  color: #646667;
}

.terms-globe-img {
  width: 30px;
  max-width: 100%;
  margin-right: 10px;
}

.terms-discription-point {
  width: calc(100% - 40px);
}

.terms-rules-title {
  margin-bottom: 55px;
}

.terms-service-user .policy-rukes-discription {
  margin-bottom: 20px;
  font-weight: 600;
}

.terms-service-discription {
  margin-top: 40px;
}

.tetms-interim {
  font-weight: 600;
  font-size: 14.5387px;
  line-height: 20px;
  text-align: center;
  color: #acb4ba;
  position: relative;
  margin: 50px 0 0;
}

.tetms-interim::before {
  content: "";
  position: absolute;
  left: 23%;
  top: 50%;
  transform: translateY(-50%);
  width: 95px;
  max-width: 100%;
  height: 1px;
  background-color: #acb4ba;
}

.tetms-interim::after {
  content: "";
  position: absolute;
  right: 23%;
  top: 50%;
  transform: translateY(-50%);
  width: 95px;
  max-width: 100%;
  height: 1px;
  background-color: #acb4ba;
}

.policy-content {
  display: flex;
}

.policy-details-content {
  width: calc(100% - 400px);
  height: calc(100% - 66px);
  overflow-y: scroll;
  padding: 80px;
}

.terms-rules-discription {
  margin-bottom: 22px;
  line-height: 20px;
}

.terms-service-user .policy-rukes-discription {
  color: #292a2c;
}

.policy-contact {
  padding-top: 100px;
}

.policy-rules {
  width: 632px;
  max-width: 100%;
}

/****** Privacy Policy Section End ******/

/***** Refer Section Start ******/

.refer-section {
  padding: 80px 0 146px;
}

.refer-main-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: #17146b;
  margin-bottom: 20px;
  font-family: "PT Sans", sans-serif;
}

.refer-discription {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #292a2c;
  max-width: 715px;
}

.ferer-share-link {
  font-weight: 400;
  text-decoration: none;
}

.refer-input {
  border: 1px solid #acb4ba;
  border-radius: 50px;
  padding: 14px 32px;
  width: 602px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.refer-input input {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  width: 100%;
  border: none;
  margin-right: 40px;
}

.refer-input input::placeholder {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.refer-input input:focus {
  outline: none;
  box-shadow: none;
}

.refer-btn {
  padding: 12px 75.5px;
}

.refer-work-content {
  border-width: 1px 0;
  border-style: solid;
  border-color: #acb4ba;
  margin: 80px 0;
  padding: 80px 0 55px;
}

.refer-work-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  display: flex;
  align-items: center;
  color: #17146b;
  margin-bottom: 50px;
  font-family: "PT Sans", sans-serif;
}

.refer-questions-title {
  margin-bottom: 30px;
}

.refer-work-item-no {
  border: 2px solid #292a2c;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #292a2c;
  margin-bottom: 20px;
}

.refer-work-item-title {
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  color: #292a2c;
  margin-bottom: 20px;
}

.refer-work-discription {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #646667;
  margin-bottom: 0;
}

.refer-work-item {
  width: 318px;
  max-width: 100%;
}

.refer-questions-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.refer-questions-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #000000;
  margin-bottom: 20px;
  display: inline-block;
}

/***** Refer Section End ******/

/* ***************** Home Page Start  ******************** */

.po-landing-section {
  padding: 70px 0 163px;
  background-color: #ebf7ff;
  position: relative;
}

.po-landing-img {
  position: absolute;
  bottom: 0;
  z-index: 0;
  left: 0;
  width: 100%;
}

.po-landing-img img {
  width: 100%;
}

.po-landing-content__inner {
  padding: 52px 52px 70px 42px;
  background-color: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 820px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 10;
}

.po-landing-section-title {
  position: relative;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  text-align: center;
  color: #17146b;
  font-family: "PT Sans", sans-serif;
  max-width: 430px;
  margin: 0 auto 15px;
}

.po-scrolling-text-label {
  position: relative;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #959da3;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.po-scrolling-text-label:after,
.po-scrolling-text-label:before {
  content: "";
  position: absolute;
  width: 88px;
  height: 0.25px;
  background-color: #acb4ba;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.po-scrolling-text-label:after {
  left: unset;
  right: 30px;
}

.po-landing-section-subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #17146b;
  text-align: center;
  margin-bottom: 30px;
}

.po-landing-form .form-control {
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 10px;
  border: 0.5px solid #d6d6d6;
  padding: 11px 55px 12px 68px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #17146b;
}

.po-landing-form .form-control::placeholder {
  color: #17146b;
}

.po-landing-form-input {
  position: relative;
  margin-right: 20px;
  width: calc(100% - 161px);
}

.po-landing-form-search-icon {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 20px;
}

.po-landing-form-cancel-icon {
  position: absolute;
  right: 25px;
  z-index: 1;
  top: 10px;
  cursor: pointer;
}

.po-landing-form-input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 42px 0 5px;
}

.po-landing-form-input-group .btn {
  padding: 12px 23.5px;
  font-size: 16px;
  line-height: 22px;
}

.po-service-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.po-service-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #008ef9;
  text-decoration: none;
  padding: 4px 8px;
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.6;
  border: 1px solid #008ef9;
  border-radius: 8px;
  display: block;
}

.po-service-item {
  margin-right: 20px;
}

.po-more-service-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #17146b;
  text-decoration: none;
}

.po-trending-moments-title {
  margin-bottom: 50px;
}

.po-trending-moments-section {
  padding: 80px 0 100px;
}

.po-trending-slider {
  width: 1160px;
  max-width: 100%;
  margin: 0 auto 50px;
}

.po-trending-slider .slick-list {
  padding-left: 0 !important;
}

.po-trending-card-img img {
  width: 95%;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 25px;
}

.po-trending-slide {
  margin: 0;
}

.po-trending-card-link {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #17146b;
  text-decoration: none;
  margin-top: 25px;
  display: inline-block;
}

.slick-arrow {
  font-size: 0;
  border: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: 0;
  transition: all 0.3s;
}

.slick-next {
  right: -25px;
}

.slick-prev::before {
  content: "\f104";
  position: absolute;
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  font-size: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slick-next::before {
  content: "\f105";
  position: absolute;
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  font-size: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/******* Event Section Start *********/

.event-section {
  padding: 100px 0 20px;
}

.event-content {
  width: 824px;
  margin: 0 auto;
  text-align: center;
}

.event-content .row {
  justify-content: space-between;
}

.event-item-img {
  background-color: #d5edff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
}

.event-item-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #17146b;
  margin-bottom: 0;
}

/******* Event Section End *********/

/******* How Work Section Start *******/

.how-work-section {
  padding: 58px 0 80px;
  position: relative;
  background-color: #ffe6a2;
}

.how-work-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 988px;
  max-width: 100%;
}

.section-title {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  color: #17146b;
}

.how-work-heading-img {
  margin-right: 17px;
}

.how-work-list {
  background-color: #fff;
  border-radius: 10px;
  padding: 52px 101px 54px 118px;
  margin: -24px auto 0;
  width: 988px;
  max-width: 100%;
}

.how-work-item {
  text-align: center;
  padding: 0 4px;
}

.how-work-pen-img {
  background-color: #d5edff;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 28px;
}

.how-work-item-img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 28px;
}

.how-work-calander-img {
  background-color: #d2ebc6;
}

.how-work-user-img {
  background-color: #ffd4bc;
}

.how-work-item-img img {
  width: 60px;
  max-width: 100%;
}

.how-work-item-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #1e1c60;
  margin-bottom: 12px;
}

.section-discription {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #1e1c60;
  margin-bottom: 0;
}

.how-wowk-btn {
  margin-top: 34px;
}

.how-wowk-btn a {
  padding: 10.5px 47px;
}

.how-work-slider-title {
  margin-bottom: 50px;
}

.how-work-slider-item {
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0.5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 29px 12px 29px 20px;
  margin: 0 10px;
}

.how-work-profile {
  display: flex;
  align-items: center;
}

.how-work-profile-img {
  margin-right: 10px;
}

.how-work-profile-img img {
  border-radius: 50%;
}

.how-work-profile-review {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.how-work-profile-title {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #17146b;
  margin-bottom: 0;
}

.review-modal-meass-box h6 {
  margin-bottom: 18px;
}

.how-work-profile-elite span {
  color: #008ef9;
}

.how-work-profile-elite {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.how-work-profile-elite img {
  margin-right: 10px;
}

.how-work-profile-elite i {
  margin-right: 10px;
  color: #008ef9;
}

.how-work-profile-review i {
  color: #ffbb00;
  font-size: 14px;
  margin-right: 1px;
}

.how-work-profile-review p {
  color: #292a2c;
  margin-left: 3px;
  display: inline-flex;
}

.how-work-profile-review span {
  color: #959da3;
  margin-left: 4px;
}

.how-work-profile-discription {
  color: #292a2c;
  margin-bottom: 5px;
}

.how-work-profile-discription i {
  margin-right: 10px;
}

.how-work-profile-love {
  margin-top: 20px;
  color: #959da3;
  font-style: italic;
}

.how-work-slider-list {
  margin-bottom: 30px;
}

.how-work-slider-list .slick-list {
  padding: 0px 45px 10px 0 !important;
}

/******* How Work Section End *******/

/******** Personal Information Section Start ********/

.information-logo {
  width: 117px;
  max-width: 100%;
  margin-right: 100px;
}

.information-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0 27px;
}

.information-nav .information-nav-link {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #008ef9;
  opacity: 0.5;
  border: none;
  padding: 0;
  transition: all 0.5s ease-in-out;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #008ef9;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active i {
  color: #fff;
  opacity: 1;
  background-color: #008ef9;
}

.information-nav-link i {
  background-color: #d0ebff;
  border-radius: 50%;
  width: 20px;
  font-size: 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

/* Personal Infromation Tabs Start */

.information-tab-body {
  padding-bottom: 100px;
}

.information-form-content {
  border-top: 0.5px solid #008ef9;
  padding-top: 80px;
}

.information-tab-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 985px;
  max-width: 100%;
  margin-left: auto;
  padding-bottom: 32px;
  text-align: center;
}

.progress {
  border-radius: 10px;
  height: 10px;
  width: calc(100% - 200px);
  max-width: 100%;
  background-color: #d0ebff;
}

.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.information-progress-title h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #008ef9;
  display: flex;
  align-items: center;
}

.information-progress-title {
  width: 250px;
  max-width: 100%;
}

.information-progress-title h6 span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #17146b;
  margin-right: 5px;
}

.information-form-back i {
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0.5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #17146b;
}

.information-form-box {
  display: flex;
  align-items: start;
}

.information-form {
  background-color: #ffffff;
  border: 0.5px solid #acb4ba;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 570px;
  max-width: 100%;
  padding: 60px 34px 42px;
  margin: 0 auto;
}

.information-form-title {
  margin-bottom: 55px;
  font-size: 25px;
  line-height: 32px;
}

.information-form-input label {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #17146b;
  margin-bottom: 10px;
}

.opacity-25 {
  opacity: 0;
}

.information-form-input {
  margin-bottom: 40px;
}

.information-form-input .form-control {
  border: 0.5px solid #959da3;
  border-radius: 10px;
  padding: 14px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #959da3;
}

.information-form-input .form-control::placeholder {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #959da3;
}

.information-form-input span {
  display: block;
  margin: 5px 0 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #959da3;
}

.information-policy {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #646667;
  max-width: 430px;
  margin: 30px auto 0;
}

.information-policy a {
  text-decoration: none;
  font-weight: 600;
  color: #008ef9;
}

.information-btn {
  font-size: 16px;
  line-height: 22px;
  padding: 12px 15px;
}

/* Personal Infromation Tabs End */

/* Upload Profile Tabs Start */

.information-profile {
  padding: 48px 35px 58px;
}

.information-profile-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #17146b;
  margin-bottom: 35px;
  font-family: "PT Sans", sans-serif;
}

.information-profile-img {
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 200px;
  max-width: 100%;
}

.profile-uload {
  position: relative;
}

.profile-uload img {
  border-radius: 50%;
}

.information-profile-upload label {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008ef9;
  position: relative;
}

.information-profile-upload label input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.information-profile-upload {
  position: absolute;
  right: 4px;
  bottom: 0;
}

.information-upload-selfie a {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffbb00;
  margin-top: 30px;
}

.information-upload-selfie {
  margin-bottom: 0;
  margin-top: 30px;
}

.information-ptofile-discription {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #959da3;
}

.information-profile-points {
  margin: 30px auto 30px;
  max-width: 443px;
}

.profile-uload-list {
  margin-top: 20px;
  margin-bottom: 0;
}

/* Upload Modal Start */

.upload-modal-body {
  padding: 47px 52px;
  text-align: center;
}

.upload-modal-content {
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.05);
  border-radius: 12px !important;
}

.upload-modal-img {
  margin-bottom: 30px;
}

.upload-modal-discription {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #292a2c;
  margin-bottom: 10px;
}

.upload-modal-policy {
  font-size: 12px;
  line-height: 20px;
  max-width: 206px;
  margin: 0 auto;
}

.upload-modal-policy a {
  text-decoration: none;
  color: #008ef9;
}

.upload-dialog {
  max-width: 400px;
  margin: 1.75rem auto;
}

.upload-modal-btn .btn {
  padding: 12px 62px;
  margin-top: 30px;
}

/* Upload Modal End */

/* Upload Profile Tabs End */

/* Pyment Method Tabs Start */

.information-payment-form {
  padding: 75px 44px 43px;
}

.information-payment-addcard {
  padding: 62px 34px 55px;
  display: none;
}

.information-payment-title {
  text-align: start;
}

.information-addcard-form {
  margin-top: 30px;
}

.addcard-input {
  position: relative;
}

.addcard-input .form-control {
  color: #292a2c;
}

.addcard-input .form-control::placeholder {
  color: #292a2c;
}

.addcard-cancel-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translatey(-50%);
}

.information-form-select {
  margin-bottom: 40px;
}

/* Pyment Method Tabs End */

/* Profile Modal Start */

.profile-modal-body {
  padding: 60px 100px 72px;
}

.profile-modal-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  color: #17146b;
  margin-bottom: 0;
  padding: 0 35px;
  font-family: "PT Sans", sans-serif;
}

canvas {
  display: block;
  width: 500px;
  height: 300px;
  margin: 0 auto;
  position: absolute;
  top: -40px;
  left: 0;
}

.profile-modal-name {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
}

.profile-modal-country {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
}

.profile-progress {
  width: 250px;
  height: 250px;
  line-height: 250px;
  background: none;
  margin: 30px auto;
  box-shadow: none;
  position: relative;
}

.profile-progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.profile-progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.profile-progress .progress-left {
  left: 0;
}

.profile-progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.profile-progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 125px;
  border-bottom-right-radius: 125px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.profile-progress .progress-right {
  right: 0;
}

.profile-progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 125px;
  border-bottom-left-radius: 125px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.profile-progress .progress-value {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  top: 0;
  /* left: 10.5%; */
}

.profile-progress.blue .progress-bar {
  border-color: #28a0fa;
}

.profile-progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

/* @media only screen and (max-width: 990px) {
    .progress {
        margin-bottom: 20px;
    }
} */

/* Profile Modal End */

/******** Personal Information Section End ********/

/********* Review Book Section Start **********/

.r-book-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 0;
  border-bottom: 0.5px solid #008ef9;
}

.r-book-logo {
  margin-right: 134px;
}

.r-book-progress {
  margin: 0;
  padding-left: 0;
  padding-bottom: 0;
  flex-direction: column;
  align-items: start;
  width: 950px;
}

.r-book-progress-title h6 span {
  font-size: 13px;
  line-height: 18px;
}

.r-book-progress-title {
  margin-bottom: 5px;
}

.r-book-body {
  padding: 80px 0 164px;
  display: flex;
  align-items: start;
}

.r-book-payment-content {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
}

.r-book-payment {
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  padding: 43px 50px 58px;
  margin-bottom: 32px;
}

.r-book-payment-title {
  margin-bottom: 30px;
}

.r-book-payment-headline {
  max-width: 100%;
  margin: 0 0 23px;
}

.r-book-payment-headline img {
  margin-right: 15px;
}

.r-book-payment-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.r-book-details-title,
.r-book-details-subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #17146b;
  margin-bottom: 17px;
}

.r-book-details-title img {
  margin-left: 8px;
}

.r-book-details-subtitle {
  color: #959da3;
  font-weight: 400;
  margin-bottom: 10px;
}

.r-book-payment-details .r-book-addres {
  max-width: 100%;
}

.r-book-payment-details .r-book-addres p {
  margin-bottom: 15px;
}

.r-book-payment-profile .r-book-profile-star img {
  width: 15px;
  margin-right: 3px;
}

.r-book-profile-star {
  color: #292a2c;
  justify-content: center;
}

.r-book-payment .r-book-payment-map {
  margin: 15px 0 30px;
}

.r-book-payment-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  margin-bottom: 30px;
  border-width: 0.5px 0;
  border-style: solid;
  border-color: #acb4ba;
}

.r-book-payment-order span {
  border: 0.25px solid #292a2c;
  border-radius: 2px;
  padding: 0 3px;
  margin-right: 5px;
}

.r-book-payment-order {
  color: #646667;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.r-book-card {
  display: flex;
  align-items: center;
}

.r-book-card-more i {
  color: #008ef9;
  font-size: 21px;
}

.r-book-card-details {
  margin-right: 30px;
}

.r-book-price-title {
  margin-bottom: 10px;
}

.r-book-price-point {
  justify-content: space-between;
  margin-bottom: 7px;
}

.r-book-price-point img {
  width: 15px;
  margin-left: 7px;
}

.r-book-price-point span {
  display: flex;
  align-items: center;
}

.r-book-total-payment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  margin-top: 30px;
  border-top: 0.5px solid #acb4ba;
}

.r-book-payment-policy {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #646667;
  margin-bottom: 20px;
}

.r-book-payment-policy a {
  text-decoration: none;
  color: #008ef9;
}

.r-book-payment-btn .btn {
  font-size: 16px;
  line-height: 22px;
  padding: 12px 71px;
  margin-top: 40px;
}

.r-book-payment-celebration {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.r-book-celebration-img {
  margin-right: 20px;
}

.r-book-celebration-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #17146b;
  margin-bottom: 5px;
}

.r-book-celebration-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  color: #17146b;
  margin-bottom: 0;
}

.r-book-addcode {
  border-bottom: 0;
  padding-bottom: 0;
  margin-top: 30px;
}

/********* Review Book Section Start **********/

/******** Confirmed Section Start ********/

.confirmed-section {
  padding: 90px 0 195px;
}

.confirmed-address {
  border: 0.5px solid #acb4ba;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 63px 50px 60px;
  width: 600px;
  max-width: 100%;
}

.confirmend-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #17146b;
  margin-bottom: 9px;
}

.confirmed-section .massage-moment-title {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 0;
}

.confirmed-section .massage-moment-image {
  width: 156px;
}

.confirmed-section .massage-moment-profile {
  width: 100%;
  margin-right: 0;
}

.confirmed-profile-details {
  margin: 16px 0;
}

.confirmed-section .order-address-link {
  font-size: 20px;
  line-height: 27px;
}

.confirmed-section .massage-update-moment {
  max-width: 400px;
  margin: 0 auto;
}

.confirmed-section .massage-update-edite a {
  font-size: 20px;
  line-height: 27px;
  color: #008ef9;
}

.plus-one-moment-detail .massage-update-edite a {
  font-size: 20px;
  line-height: 27px;
  color: #008ef9;
}

.confirmed-section .massage-update-edite a:first-child {
  font-size: 20px;
  line-height: 27px;
  margin: 20px 0;
  padding: 10px 0;
  border: 1px solid #ffbb00;
  border-radius: 50px;
  color: #ffbb00;
}

.plus-one-moment-detail .massage-update-edite a:first-child {
  font-size: 20px;
  line-height: 27px;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ffbb00;
  border-radius: 50px;
  color: #ffbb00;
}

.confirmed-chat {
  border: 0.5px solid #acb4ba;
  width: 500px;
  max-width: 100%;
  margin-bottom: 15px;
  background-color: #f0f4f8;
  border-radius: 12px;
  padding: 24px 60px 68px;
}

.confirmed-chat .price-profile {
  padding: 24px 31px 24px 22px;
  border-bottom: 0.5px solid #acb4ba;
}

.confirmed-chat .price-profile-img {
  width: 80px;
  height: 80px;
  margin-right: 5px;
}

.confirmed-chat .profile-details-title {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
}

.confirmed-chat .price-details-price h4 {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}

.slecting-body-profile-more {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #ffbb00;
  display: block;
  margin-top: 20px;
}

.slecting-body-profile-more:hover {
  color: #ffbb00;
  opacity: 0.8;
}

.confirmed-chat .profile-about-disctipion {
  font-size: 15px;
  line-height: 20px;
}

.confirmed-chat .profile-verified {
  margin: 8px 0 5px;
}

.confirmed-chat-box a {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #008ef9;
  text-align: center;
  margin-bottom: 0;
  padding: 15px 0;
  display: block;
  text-decoration: none;
}

.confirmed-helth p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #292a2c;
  width: 410px;
  margin-bottom: 0;
}

.confirmed-helth p img {
  margin-right: 17px;
}

.condirmed-heading {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.condirmed-heading .confirmend-title {
  width: 580px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.confirmed-address-input {
  margin: 30px 0 20px;
}

.confirmed-input-box {
  position: relative;
}

.confirmed-input-box a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.confirmed-input-box a:nth-child(2) {
  left: 18px;
}

.confirmed-input-box a:nth-child(3) {
  right: 23px;
}

.confirmed-input-box input {
  border: 0.5px solid #008ef9;
  border-radius: 10px;
  padding: 14px 50px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #959da3;
}

.confirmed-input-box input::placeholder {
  font-weight: 600;
  font-size: 15px;
  color: #959da3;
}

.confirmed-address-input label {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 15px;
}

.confirmed-address .confirmed-profile-details {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
}

.confirmed-address .update-moment-prodile {
  margin-top: 40px;
}

.confirmed-profile-details img {
  width: 60px;
  margin-right: 20px;
}

.confirmed-address .confirmed-profile-details p {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #17146b;
}

.confirmed-update-edit a:first-child {
  border: 1px solid #ffbb00;
  border-radius: 50px;
  padding: 10.5px;
  margin-bottom: 25px;
  background-color: #fff;
}

.confirmed-update-edit a:last-child {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #008ef9;
}

.confirmed-update-edit {
  max-width: 300px;
  margin: 30px auto 0;
}

.comfirmed-help-img {
  margin-bottom: 30px;
}

.confirmed-help-title {
  line-height: 32px;
  margin-bottom: 10px;
}

.confirmed-help-discription {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #646667;
  max-width: 300px;
  margin: 0 auto 20px;
}

.confirmed-help-btn a {
  background: #ffffff;
  border: 1px solid #ffbb00;
  border-radius: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffbb00;
  padding: 13px 12px;
  width: 200px;
  max-width: 100%;
}

.confirmed-help-btn a:hover {
  color: #ffbb00;
}

.confirmed-header-input {
  width: 500px;
  max-width: 100%;
  padding: 13.5px 22px;
}

.confirmed-header-input input::placeholder {
  font-weight: 600;
  font-size: 14px;
  color: #292a2c;
}

.confirmed-header-input::before {
  display: none;
}

.confirmed-header-input .form-control {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #292a2c;
}

.confirmed-header-input .header-btn {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
}

.btn-close:focus {
  box-shadow: none;
}

.confirmed-input-box {
  position: relative;
}

.confirmed-input-box a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.confirmed-input-box a:nth-child(2) {
  left: 18px;
}

.confirmed-input-box a:nth-child(3) {
  right: 23px;
}

.confirmed-input-box input {
  border: 1px solid #008ef9;
  border-radius: 10px;
  padding: 14px 50px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #959da3;
}

.confirmed-input-box input::placeholder {
  font-weight: 600;
  font-size: 15px;
  color: #959da3;
}

/******** Confirmed Section End ********/

/******* Payment Method Section Start *******/

.p-method-heading .payment-book-title {
  width: 100%;
  margin-bottom: 10px;
}

.p-method-title {
  margin-bottom: 10px;
}

.p-method-headline {
  margin-bottom: 47px;
}

.p-method-body {
  padding-bottom: 63px;
  margin-bottom: 0;
}

.r-book-details-title del {
  display: unset;
  margin-right: 20px;
  color: #959da3;
}

.p-method-footer {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 0.5px solid #acb4ba;
}

.p-method-promocode {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 30px;
}

.p-method-promo .p-method-code-title {
  color: #008ef9;
  margin-bottom: 4px;
  text-decoration: none;
}

.p-method-code-title img {
  margin-left: 10px;
}

.p-method-promocode .p-method-code-discount {
  color: #292a2c;
  font-weight: 600;
}

.p-method-btn .btn {
  margin-top: 30px;
}

.selecting-book-payment {
  padding-bottom: 164px;
}

/* Requesting Modal Start */

.requesting-modal-dialog {
  max-width: 450px;
}

.requesting-modal-content {
  border-radius: 12px;
}

.requesting-modal-body .btn-close {
  position: absolute;
  background-image: none;
  top: 25px;
  right: 25px;
  padding: 0;
}

.requesting-modal-body {
  padding: 57px 75px;
}

.requesting-modal-body .requesting-modal-title {
  line-height: 35px;
  margin: 30px 0;
}

.requesting-modal-body .btn-warning {
  padding: 12px;
}

.requesting-modal-discription {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #292a2c;
  margin: 10px 0 0;
}

/* Requesting Modal End */

/* Confirm Cancel Modal Start */

.confirmcancel-modal .requesting-modal-body {
  padding: 67px;
}

.confirmcancel-modal .requesting-modal-title {
  margin-bottom: 10px;
}

.confirmcancel-modal .requesting-modal-discription {
  font-size: 15px;
  line-height: 25px;
  margin: 0 auto 20px;
  max-width: 260px;
}

.confirmcancel-btn {
  border: 1px solid #ffbb00;
  border-radius: 50px;
  margin-top: 15px;
  padding: 13px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffbb00;
}

.cancel-profile-mbody {
  padding: 0px 25px 46px;
}

.cancel-profile-reason input {
  border: 2px solid #acb4ba;
}

.cancel-profile-heading {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.cancel-profile-heading .information-form-back {
  margin-right: 46px;
}

.cancel-profile-heading .information-form-back i {
  width: 37px;
  height: 37px;
}

.cancel-profile-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 16px;
}

.cancel-profile-reason label {
  font-weight: 400;
  margin-left: 7px;
}

.cancel-profile-reason .form-check {
  margin-bottom: 20px;
}

.cancel-profile-reason .form-check:last-child {
  margin-bottom: 0;
}

.cancel-profile-input {
  margin: 28px 0 30px;
}

.cancel-profile-btn .btn {
  margin-top: 10px;
  padding: 12px 119.5px;
}

.cancelled-modal .requesting-modal-img {
  width: 70px;
  margin: 0 auto 10px;
}

.cancelled-modal .requesting-modal-body {
  padding: 36px 75px;
}

/* Confirm Cancel Modal End */

/* Success Modal Start */

.success-modal .requesting-modal-body {
  padding: 75px 82px;
}

.success-modal-img {
  margin-bottom: 37px;
}

.success-modal-subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #17146b;
  margin-bottom: 0;
}

.success-modal-title {
  font-weight: 700;
  font-size: 45px;
  line-height: 61px;
  text-align: center;
  color: #17146b;
  margin: 0;
}

/* Success Modal End */

/******** Confirmed Section End ********/

/******* Payment Method Section Start *******/

.p-method-heading .payment-book-title {
  width: 100%;
  margin-bottom: 10px;
}

.p-method-title {
  margin-bottom: 10px;
}

.p-method-headline {
  margin-bottom: 47px;
}

.p-method-body {
  padding-bottom: 63px;
  margin-bottom: 0;
}

.r-book-details-title del {
  display: unset;
  margin-right: 20px;
  color: #959da3;
}

.p-method-footer {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 0.5px solid #acb4ba;
}

.p-method-promocode {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 30px;
}

.p-method-promo .p-method-code-title {
  color: #008ef9;
  margin-bottom: 4px;
  text-decoration: none;
}

.p-method-code-title img {
  margin-left: 10px;
}

.p-method-promocode .p-method-code-discount {
  color: #292a2c;
  font-weight: 600;
}

.p-method-btn .btn {
  margin-top: 30px;
}

.selecting-book-payment {
  padding-bottom: 164px;
}

/* Voucher Modal Start */

.voucher-modal-dialog {
  max-width: 600px;
}

.voucher-modal-body {
  padding: 30px;
}

.voucher-modal-title {
  color: #0b0872;
  text-align: start;
  margin-bottom: 24px;
}

.voucher-modal-code {
  display: flex;
  align-items: center;
}

.voucher-modal-radio:first-child {
  margin-right: 20px;
  width: 20px;
  height: 20px;
  border: 1.5px solid #acb4ba;
  margin-top: 0;
}

.voucher-modal-radio:checked[type="radio"] {
  background-image: url("../images/voucher_dot.png");
  background-size: 13px;
  background-position-x: 1px;
  background-position-y: 1.9px;
}

.voucher-modal-radio:checked {
  background-color: #fff;
}

.voucher-modal-code input:last-child {
  max-width: 220px;
  padding: 14px 20px;
  font-size: 15px;
  line-height: 20px;
}

.voucher-modal-code input:last-child:focus {
  border-color: #acb4ba;
}

.voucher-modal-details {
  padding: 30px 10px 30px 0;
  margin: 30px 0;
  border-bottom: 0.5px solid #acb4ba;

  display: flex;
  align-items: start;
}

.discount-text {
  color: #008ef9;
  font-weight: 600;
}

.voucher-modal-view-details {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.voucher-modal-body .voucher-details-title {
  font-weight: 600;
  color: #292a2c;
  margin-bottom: 0;
}

.vouche-details-subtitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: block;
  color: #292a2c;
}

.voucher-valid-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: block;
  color: #646667;
  margin: 5px 0 20px;
}

.voucher-details-btn {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #959da3;
}

.voucher-details-btn:hover {
  color: #959da3;
}

.coucher-other-details {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.voucher-other-moment {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #acb4ba;
  margin-bottom: 0;
}

.voucher-other-promo {
  padding-left: 30px;
}

.voucher-other-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 13px;
}

.voucher-other-code {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #acb4ba;
}

.voucher-other-date {
  color: #acb4ba;
  margin-bottom: 30px;
}

.voucher-modal-btn .btn {
  padding: 12px 75.5px;
  font-size: 16px;
  line-height: 22px;
}

/* Voucher Modal End */

/******* Payment Method Section End *******/

.features-section {
  padding: 80px 0 122px;
}

.p-method-heading {
  margin: 0 auto 40px;
}

/****** Misson Section Start *********/

.mission-section {
  position: relative;
  background-color: #ebf7ff;
}

.mission-section::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  /* background-color: #ebf7ff; */
  width: 100%;
  height: calc(100vh - 82px);
}

.mission-tree-img {
  margin-bottom: 60px;
}

.mission-tree-img img {
  margin-top: -64px;
}

.mission-details-title {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: #17146b;
  margin-bottom: 15px;
}

.mission-details-discription {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  color: #04033c;
  margin-bottom: 40px;
}

.mission-men-img img {
  margin-bottom: -142px;
}

.massage-moment-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  color: #17146b;
  margin-bottom: 17px;
}

.massage-moment-user {
  display: flex;
  align-items: center;
}

.massage-moment-heading {
  display: flex;
  align-items: center;
}

.massage-moment-profile-img {
  width: 40px;
  height: 40px;
  max-width: 100%;
  margin-right: 5px;
}

.massage-moment-profile-img {
  border-radius: 50%;
}

.massage-moment-profile-details p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #17146b;
  margin-bottom: 0;
}

.massage-moment-image {
  width: 100px;
  max-width: 100%;
}

.massage-moment-profile {
  width: 100%;
  margin-right: 17px;
}

.massage-moment-profile-details {
  width: 100%;
}

.massage-momemt-map {
  margin: 30px 0 25px;
}

.massage-update-edite {
  margin-top: 30px;
}

.massage-update-moment {
  max-width: 195px;
  margin: 0 auto;
}

.massage-update-moment p {
  margin-bottom: 15px;
}

.massage-update-edite a {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffbb00;
  margin-bottom: 10px;
}

.massage-update-edite a svg {
  margin-right: 10px;
}

.massage-moment-address {
  padding: 30px 0;
  margin: 30px 0;
  border-width: 0.5px 0;
  border-style: solid;
  border-color: #acb4ba;
}

.massage-address-verified {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #008ef9;
  margin-bottom: 14px;
}

.massage-address-verified svg,
.massage-address-link svg {
  margin-right: 10px;
}

.massage-address-link {
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #646667;
  margin-bottom: 8px;
}

.massage-review-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #17146b;
  margin-bottom: 35px;
}

.massage-reviews-name {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #17146b;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.massage-reviews-name img {
  margin-left: 10px;
}

.massage-moment-review small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #959da3;
}

/****** Misson Section End *********/

/********** FAQ Section Start ***********/

.faq-section {
  padding: 238px 0 191px;
}

.faq-content {
  width: 720px;
  max-width: 100%;
  margin: 0 auto;
}

.faq-title {
  text-align: center;
  margin-bottom: 60px;
}

.accordion-button::after {
  background-image: url("../images/faq-plus.png");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../images/faq-minus.png");
  transform: rotate(0);
}

.accordion-button:not(.collapsed) {
  color: #17146b;
  background-color: #fff;
  box-shadow: none;
  padding-bottom: 20px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-item {
  border: none;
  border-bottom: 1px solid #acb4ba;
}

.accordion-button {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #17146b;
  padding: 40px 20px 40px 0;
}

.faq-discription {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #272c50;
  margin-bottom: 40px;
}

/********** FAQ Section End ***********/

/******** Errands Section Start ********/

.errands-tabs-heading {
  display: flex;
  align-items: center;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 25px 0 20px;
}

.errands-logo {
  margin-right: 128px;
}

.errands-tabs-color {
  background-color: #ebf7ff;
  border-bottom: 1px solid #008ef9;
}

/* .nav-tabs .nav-link {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #17146B;
    z-index: 2;
    position: relative;
    padding: 0;
}

.nav-tabs .nav-link::before {
    content: "";
    position: absolute;
    top: 12px;
    background-color: #ACB4BA;
    width: 135px;
    height: 1px;
    z-index: -1;
}

.nav-tabs .nav-item:nth-child(5) .nav-link::before {
    display: none;
}

.nav-tabs .nav-link:hover {
    border-color: transparent;
    color: #17146B;
    opacity: 1;
} */

.errands-btn-no {
  display: block;
  background-color: #acb4ba;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 auto 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 19px;
  color: #ffffff;
}

/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #17146B;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active .errands-btn-no {
    background-color: #17146B;
} */

.nav-tabs .nav-item {
  margin: 0 60px;
}

.nav-tabs .nav-item:first-child {
  margin-left: 0;
}

.errands-tabs-discription {
  border-bottom: 1px solid #008ef9;
  padding: 23px 0;
  background-color: #ebf7ff;
}

.errands-tabs-discription p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #646667;
}

/* Errands Tab Start */

.location-body {
  padding: 130px 0 122px;
  display: flex;
}

.errands-tab-body {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
}

.errands-tab-body-title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #17146b;
  margin-bottom: 33px;
}

.errands-body-address-box {
  background-color: #ffffff;
  padding: 35px 33px 46px 35px;
  border: 0.5px solid #d6d6d6;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.errands-address-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 20px;
  color: #17146b;
}

.set-schedule-content {
  padding: 80px 0 130px;
}

.set-schedule-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #17146b;
}

.set-schedule-content-description {
  margin-top: 50px;
}

.set-schedule-description {
  width: 455px;
  max-width: 100%;
  margin: 0 auto;
}

.set-schedule-input .select2-container .select2-selection--single .select2-selection__rendered {
  padding: 10px 0 10px 20px;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
}

.set-schedule-input .select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none;
}

.set-schedule-input .select2-container--default .select2-selection--single {
  border: none;
}

.set-schedule-input {
  margin-bottom: 40px;
}

.set-schedule-input .form-control {
  border: 0.5px solid #008ef9;
  border-radius: 10px;
  padding: 12px 20px 14px 20px;
}

.set-schedule-input-title span {
  height: 25px;
  width: 25px;
  max-width: 100%;
  background-color: #008ef9;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 15px;
}

.set-schedule-input-title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
}

.set-schedule-contain {
  display: flex;
  justify-content: space-between;
}

.js-example-templating {
  width: 100%;
  border: 0.5px solid #008ef9;
  border-radius: 10px;
  padding: 12px 20px 14px 20px;
}

.location-detail {
  display: flex;
}

.location-main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
  margin: 0 0 0 10px;
}

.location-title {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
}

.set-schedule-button .btn {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding: 12px 27px;
}

.set-schedule-map iframe {
  height: 545px;
  width: 450px;
  max-width: 100%;
}

.select2-container {
  width: 100% !important;
}

.select2-container .select2-selection--single {
  height: auto;
}

.errands-location-input {
  border-bottom: 2px solid #f7f7f9;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.errands-location-input .form-control {
  background-color: #f9fafb;
  border-radius: 10px;
  border: gainsboro;
  padding: 13px 20px 13px 60px;
  background-image: url("../images/errands_location.png"),
    url("../images/close.png");
  background-repeat: no-repeat;
  background-position: left 20px center, right 20px center;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #959da3;
}

.errands-location-input .form-control::placeholder,
.contesearch .form-control::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #959da3;
}

.errands-radio-btn .form-check-input[type="radio"] {
  display: none;
}

.errands-radio-label {
  display: block;
  background-color: #f9fafb;
  border-radius: 12px;
  padding: 9px 20px 9px 30px;
  background-image: url("../images/Icon_Location.png");
  background-repeat: no-repeat;
  background-position: right 20px center;
  border: 1px solid transparent;
}

.errands-radio-label span {
  font-style: normal;
  font-weight: 600;
  font-size: 10.5px;
  line-height: 14px;
  color: #646667;
}

.errands-radio-label h6,
.errands-set-location {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #17146b;
  margin-bottom: 0;
}

.errands-radio-btn {
  margin-bottom: 20px;
}

.errands-radio-btn input:checked+.errands-radio-label {
  border: 1px solid #acb4ba;
}

.errands-set-location {
  background-image: url("../images/Icon_Location.png");
  background-repeat: no-repeat;
  background-position: right 20px center;
  border: 1px solid #acb4ba;
  border-radius: 12px;
  padding: 16.5px 20px 16.5px 30px;
  display: block;
  text-decoration: none;
  transition: all 0.3s;
  margin-bottom: 30px;
}

.errands-set-location:hover {
  background-color: #f9fafb;
  color: #17146b;
  transition: all 0.3s;
}

.errands-location-btn .btn {
  padding: 12px 84.5px;
  font-size: 16px;
  line-height: 22px;
  margin-top: 10px;
}

.errands-map-box {
  padding: 40px 0 0 102px;
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.errands-map-title {
  margin-bottom: 43px;
}

.errands-map-btn {
  margin-top: 30px;
}

.erratds-caledar-title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
}

.table-condensed {
  width: 100%;
}

.errands-calendar {
  margin-bottom: 20px;
}

.datepicker-inline {
  width: 455px;
  border: 0.521587px solid #d6d6d6;
  border-radius: 10px;
  padding: 0;
}

.errands-ranges-title {
  margin-bottom: 20px;
}

.datepicker-switch {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
}

.datepicker .dow {
  font-weight: 400;
  font-size: 15.6476px;
  line-height: 21px;
  text-align: center;
  padding: 16px 10px;
  color: #646667;
  /* border-width: 0.521587px 0;
    border-style: solid;
    border-color: #D6D6D6; */
}

.table-condensed thead tr:nth-child(2) th {
  padding: 20px;
}

.table-condensed thead tr:nth-child(3) {
  border-width: 0.521587px 0;
  border-style: solid;
  border-color: #d6d6d6;
}

.old.day {
  font-weight: 600;
  font-size: 15.6476px;
  line-height: 21px;
  text-align: center;
  color: #acb4ba;
  padding: 27px 0 12px;
}

.day {
  font-weight: 700;
  font-size: 15.6476px;
  line-height: 21px;
  text-align: center;
  color: #292a2c;
  padding: 12px 0;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.active:hover {
  background-color: #fff;
  color: #000;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover {
  background-image: url("../images/circle.png");
  background-repeat: no-repeat;
  background-position: 49%;
}

.errands-time-ranges label {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
}

.errands-time-ranges input:checked+label {
  font-weight: 600;
}

.timeset-body-heading {
  display: flex;
  align-items: center;
  /* margin-bottom: 40px; */
}

.timeset-body-heading h5 {
  width: 100%;
}

.timeset-body {
  padding: 80px 0 200px;
  width: 1097px;
  margin: 0 auto;
  max-width: 100%;
}

.timeset-times {
  display: flex;
  align-items: center;
}

.timeset-time-input label {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
}

.selecting-body-heading {
  margin: 25px 0;
}

.timeset-time-input {
  width: 200px;
  max-width: 100%;
  margin-right: 55px;
}

.timeset-time-input:last-child {
  margin-right: 0;
}

.button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Timing Modal Start */

.timing-modal-dialog {
  max-width: 400px;
}

.timing-modal-body {
  padding: 50px;
}

.timing-modal-title {
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #17146b;
  margin-bottom: 0;
}

.riming-modal-address {
  width: 234px;
  margin: 30px auto;
}

.riming-modal-address p {
  font-size: 18px;
  line-height: 25px;
  align-items: start;
  color: #292a2c;
}

.riming-modal-address p img {
  margin-right: 10px;
}

/* Timing Modal End */

/* Errands Tab End */

/* Price Tab Start */

.price-body-finding {
  background-color: #f3f3f3;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #292a2c;
  padding: 10.7px 0;
  display: block;
  margin-bottom: 0;
}

.price-body-finding img {
  margin-right: 20px;
}

.price-heading-discription {
  margin-bottom: 0;
  background-color: #0b0872;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  display: block;
  color: #ffffff;
  padding: 12px 0;
}

.price-heading-discription img {
  margin-left: 30px;
}

.price-tab-body {
  padding: 80px 0 93px;
}

.price-content {
  background: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  padding: 37px 32px 40px 29px;
  margin-bottom: 20px;
  width: 370px;
  max-width: 100%;
}

.price-range-content {
  border-bottom: 0.5px solid #acb4ba;
  padding-bottom: 25px;
}

span.value {
  height: 1.7em;
  font-weight: bold;
  display: inline-block;
}

span.value.lower::before {
  content: "$";
  display: inline-block;
}

span.value.upper::before {
  content: "$";
  display: inline-block;
  margin-left: 0.4em;
}

.legend {
  display: none;
}

.lower.value {
  position: absolute;
  left: 0;
  bottom: -30px;
}

.upper.value {
  position: absolute;
  right: 0;
  bottom: -30px;
}

/* Price Range Slider Start */

.price-range-slider {
  width: 100%;
  position: relative;
}

.range-value {
  margin: 0;
}

.range-value input {
  width: 100%;
  background: none;
  box-shadow: none;
  border: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
}

.range-bar {
  border: none;
  background: #000;
  height: 3px;
  width: 96%;
  margin-left: 8px;
}

.ui-slider-range {
  background: #06b9c0;
}

.ui-slider-handle {
  border: none;
  border-radius: 25px;
  background: #fff;
  border: 2px solid #06b9c0;
  height: 17px;
  width: 17px;
  top: -0.52em;
  cursor: pointer;
}

.ui-slider-handle+span {
  background: #06b9c0;
}

.ui-slider-horizontal .ui-slider-range {
  height: 5px;
}

.ui-slider-range {
  background: #008ef9;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
  border: 1px solid #008ef9;
  background: #fff;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -7px;
}

.ui-slider-horizontal .ui-slider-handle:focus {
  outline: none;
}

.range-bar {
  border: none;
  background: #008ef9;
  height: 5px;
  width: 100%;
  margin-left: 0;
}

.ui-widget.ui-widget-content {
  border: none;
}

.range-value {
  position: absolute;
  top: 20px;
  left: 0;
}

/* Price Range Slider Start */

/* webkit specific styling */

.price-range-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

.price-range-title h5:first-child {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #17146b;
  margin-bottom: 0;
}

.price-range-title a {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #959da3;
  margin-bottom: 0;
  text-decoration: none;
}

.price-range-discription {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
  margin-bottom: 0;
  padding-top: 50px;
  text-align: center;
}

.price-range-discription span {
  font-weight: 600;
}

.price-type-title {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #17146b;
  margin-bottom: 20px;
}

.price-type {
  padding: 25px 0;
  border-bottom: 0.5px solid #acb4ba;
}

.price-type-checkbox .form-check-input[type="checkbox"] {
  border-radius: 1px;
  width: 12px;
  height: 12px;
  border: 1.5px solid #acb4ba;
}

.price-type-checkbox {
  margin-bottom: 10px;
}

.price-type-checkbox:last-child {
  margin-bottom: 0;
}

.form-check-input:focus {
  box-shadow: none;
}

.price-type-checkbox label {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #292a2c;
}

.price-helth {
  background: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  display: flex;
  align-items: start;
  padding: 25px 40px 25px 25px;
  width: 370px;
  max-width: 100%;
}

.price-helth img {
  margin-right: 20px;
}

.price-helth-discription {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
  margin-bottom: 0;
}

.price-helth-discription a {
  color: #008ef9;
  font-weight: 600;
  text-decoration: none;
}

.selecting-body-profiles {
  width: 710px;
  max-width: 100%;
}

.selecting-profile-heading {
  margin: 0 0 73px 0;
}

.price-profile-content {
  background: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  padding: 50px 38px 50px 30px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
}

.price-profile {
  display: flex;
}

.price-profile-img {
  width: 150px;
  height: 150px;
  max-width: 100%;
  max-height: 100%;
}

.profile-verified {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #008ef9;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.profile-verified img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.price-profile-img img {
  border-radius: 50%;
}

.price-profile-view-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #646667;
  margin-bottom: 8px;
  display: inline-block;
}

.price-profile-view-title:hover {
  color: #646667;
}

.price-profile-review {
  margin-right: 20px;
}

.price-profile-view-discription {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #646667;
  margin-bottom: 0;
  text-align: center;
}

.price-profile-name {
  display: flex;
}

.price-details-price {
  margin-left: auto;
}

.price-profile-details {
  width: 100%;
  margin-left: 15px;
}

.price-details-price img {
  margin-left: 30px;
}

.profile-details-star i {
  font-size: 11px;
  margin-right: 4px;
}

.profile-details-star p {
  margin-left: 8px;
}

.review-payment-method {
  font-size: 18px;
  line-height: 25px;
}

.price-details-price h4 {
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #292a2c;
}

.profile-details-star span {
  margin-left: 6px;
}

.profile-details-title {
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 6px;
  color: #292a2c;
  display: flex;
  align-items: center;
}

.profile-about-disctipion {
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #959da3;
  margin-bottom: 0;
}

.profile-about-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 20px;
}

.price-profile-btn {
  margin-top: 33px;
}

.price-profile-btn .btn {
  padding: 10.5px 19px;
}

.selecting-profile-slider {
  margin: 50px 0;
}

.selecting-profile-slider .slick-list {
  padding-left: 0 !important;
}

.selecting-profile-item {
  padding: 0 7px;
}

/* Price Tab End */

/* PlusOne Profile Tab Start */

.plusone-profile-back-btn i {
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.plusone-profile-back-btn {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #17146b;
  text-decoration: none;
}

.plusone-profile-heading {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}

.plusone-heading-title {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #17146b;
  margin-bottom: 0;
  width: 90%;
}

.plusone-profile-img {
  margin-right: 30px;
}

.plusone-profile-img img {
  width: 150px;
}

.plusone-profile {
  display: flex;
  align-items: center;
}

.plusone-profile-work {
  background-color: #f9fafb;
  border-radius: 10px;
  padding: 32px 105px 39px 88px;
  margin: 50px 0 30px;
}

.profile-work-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-work-link img {
  margin-right: 20px;
}

.profile-work-link {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #646667;
  margin-bottom: 6px;
}

.plusone-profile-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #17146b;
  margin-bottom: 20px;
}

.profile-about-discription {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #646667;
  margin-bottom: 0;
  padding-right: 45px;
}

.profile-about-discription a {
  color: #4cb90b;
  font-weight: 600;
  text-decoration: none;
}

.plusone-profile-about {
  border-bottom: 0.5px solid #acb4ba;
  padding-bottom: 32px;
  margin-bottom: 30px;
}

.profile-preferred {
  justify-content: inherit;
}

.profile-preferred-item {
  margin-right: 80px;
}

.profile-preferred .profile-work-link {
  line-height: 20px;
}

.profile-review-rating img {
  margin-right: 10px;
}

.profile-review-rating {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 20px;
}

.profile-review-rating span {
  color: #959da3;
  margin-left: 5px;
}

.progress-bar {
  background-color: #008ef9;
}

.profile-review-star-rating {
  display: flex;
  align-items: baseline;
}

.profile-review-item {
  display: flex;
  align-items: center;
  margin-bottom: 12.5px;
}

.profile-review-rating-no p {
  margin-bottom: 11.5px;
  color: #959da3;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.profile-review-progress {
  margin: 0 13px 0 16px;
  width: 288px;
}

.selecting-profile-prograss {
  width: 100%;
  margin-bottom: 23px;
  background-color: #fff;
}

.selecting-profile-prograss .progress-bar {
  border-radius: 10px;
  background-color: #ffbb00;
}

.profile-review-item h6 {
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #646667;
}

.client-review-title {
  margin-bottom: 2px;
  display: flex;
  align-items: start;
}

.client-review-date {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #959da3;
  margin-bottom: 5px;
}

.plusone-client-review {
  background-color: #fff;
  border-radius: 0;
  padding: 25px 65px 25px 0;
  margin-bottom: 20px;
  border-bottom: 0.5px solid #acb4ba;
}

.client-review-title p {
  margin-left: 8px;
  margin-right: 0;
}

.client-review-title img {
  width: 15px;
}

.plusone-profile-price {
  background-color: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  padding: 32px 44px 44px;
}

.plusone-profile-price-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.plusone-profile-price-title h4 {
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  text-align: right;
  color: #292a2c;
  margin-bottom: 0;
}

.profile-hourly-rate {
  background: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  padding: 34px 28px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.hourly-rate-title,
.plusone-hourly-rate {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #17146b;
  margin-bottom: 0;
}

.plusone-hourly-rate {
  font-weight: 600;
  color: #292a2c;
}

.plusone-profile-price-discription {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin-right: 0;
}

.plusone-profile-price-discription {
  text-align: center;
  margin-right: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #646667;
  margin-bottom: 15px;
}

.selecting-profile-discription a {
  color: #008ef9;
  font-weight: 600;
}

.selecting-profile-run {
  font-size: 18px;
  line-height: 25px;
}

.selected-profile-charged {
  color: #acb4ba;
  margin-bottom: 20px;
}

.selecting-profile-footer {
  border-top: 0.5px solid #acb4ba;
  border-radius: 10px 10px 0px 0px;
  padding: 19.5px 0;
}

.selecting-profile-footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selecting-profile {
  display: flex;
  align-items: center;
}

.selecting-profile img {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  object-fit: cover;
}

.selecting-profile h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 0;
}

.selecting-profile-price {
  display: flex;
  align-items: center;
}

.selecting-profile-price-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #292a2c;
  margin-bottom: 0;
  margin-right: 20px;
}

.selecting-profile-btn {
  padding: 12px 33.6px;
}

/* PlusOne Profile Tab End */

/* Payment Tabs Start */

.plusone-payment-heading {
  margin-bottom: 50px;
}

.plusone-payment-method {
  width: 600px;
  margin: 0 auto;
  max-width: 100%;
  padding: 63px 50px 77px;
}

.payment-method-link {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 0.5px solid #acb4ba;
}

/* .payment-method-link.active svg rect {
    fill: #FFE49C;
} */

.payment-method-link.active::after {
  content: "\f00c";
  font-weight: 900;
  font-family: "Font Awesome 6 free";
  position: absolute;
  right: 50px;
  width: 30px;
  height: 30px;
  background-color: #ffbb00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.payment-card-name h5 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #646667;
}

.payment-card-img {
  margin-right: 71px;
  width: 50px;
  max-width: 100%;
}

.payment-card-icon {
  margin-left: auto;
}

.payment-method-promo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-method-promo a {
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #17146b;
}

.payment-method-promo a:last-child {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #008ef9;
}

.plusone-payment-body-hide {
  display: none;
}

.plusone-payment-fill-show {
  display: block;
  margin-top: -50px;
}

.payment-book-title {
  margin-bottom: 20px;
}

.payment-book-select {
  display: none;
}

.payment-option-list {
  padding: 15px 0;
  margin-bottom: 0;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
}

.payment-option-list img,
.payment-book-option-btn img {
  width: 34px;
}

.payment-option-list li {
  list-style: none;
  padding: 10px 20px;
}

.payment-option-list li:hover {
  background-color: #f4f3f3;
}

.payment-option-list li span,
.payment-book-option-btn li span {
  margin-left: 30px;
}

/* item list */

.payment-book-list {
  display: none;
  width: 100%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0 10px 10px;
  position: absolute;
  z-index: 1;
}

.payment-book-option {
  position: relative;
}

.open {
  display: show !important;
}

.payment-book-option-btn {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  border: 0.5px solid #acb4ba;
  padding: 12px 20px;
  background-image: url("../images/down_arrow.png");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.payment-book-option-btn li {
  list-style: none;
  float: left;
  padding-bottom: 0px;
}

.payment-book-option-btn:hover li {
  margin-left: 0px;
}

.payment-book-option-btn:hover {
  background-color: #f4f3f3;
  border: 1px solid transparent;
  box-shadow: inset 0 0px 0px 1px #ccc;
}

.payment-book-option-btn:focus {
  outline: none;
}

.payment-book-discription {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #646667;
}

.payment-book-discription img {
  margin-right: 14px;
}

.payment-book-card-details {
  padding: 62px 49px 80px;
}

.payment-book-details-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payment-book-heading-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #17146b;
  margin-bottom: 0;
}

.payment-book-logo img {
  margin-right: 10px;
}

.payment-book-select {
  display: none;
}

.payment-option-list {
  padding: 15px 0;
  margin-bottom: 0;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
}

.payment-option-list img,
.payment-book-option-btn img {
  width: 34px;
}

.payment-option-list li {
  list-style: none;
  padding: 10px 20px;
}

.payment-option-list li:hover {
  background-color: #f4f3f3;
}

.payment-option-list li span,
.payment-book-option-btn li span {
  margin-left: 30px;
}

/* item list */

.payment-book-list {
  display: none;
  width: 100%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0 10px 10px;
  position: absolute;
  z-index: 1;
}

.payment-book-option {
  position: relative;
}

.open {
  display: show !important;
}

.payment-book-option-btn {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  border: 0.5px solid #acb4ba;
  padding: 10px 20px;
  background-image: url("../images/down_arrow.png");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.payment-book-option-btn li {
  list-style: none;
  float: left;
  padding-bottom: 0px;
}

.payment-book-option-btn:hover li {
  margin-left: 0px;
}

.payment-book-option-btn:hover {
  background-color: #f4f3f3;
  border: 1px solid transparent;
  box-shadow: inset 0 0px 0px 1px #ccc;
}

.payment-book-option-btn:focus {
  outline: none;
}

.payment-card-address {
  margin: 55px 0 35px;
}

.payment-card-input .form-control {
  border: 0.5px solid #959da3;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #646667;
  /* border-radius: 12px 12px 0 0; */
}

.payment-card-input .form-control::placeholder {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.payment-card-input {
  margin-bottom: 15px;
}

.payment-card-select-country {
  position: relative;
}

.payment-card-select-country span {
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #959da3;
  left: 16px;
  top: 4px;
}

.payment-card-select-country .form-control {
  padding: 18px 16px 10px;
}

.payment-card-no {
  border: 0.5px solid #959da3;
  border-radius: 12px;
}

.payment-card-Expiration {
  display: flex;
}

.payment-card-Expiration input:last-child {
  border-radius: 0 0 12px 0;
}

.card-lock {
  border-radius: 12px 12px 0 0;
  /* background-image: url("../images/Icon_Password.png");
  background-repeat: no-repeat;
  background-position: left 122px center; */
}

.payment-card-promo {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #008ef9;
  margin-top: 25px;
  display: block;
}

.payment-book-btn .btn {
  padding: 12px 71px;
}

.plusone-payment-order {
  padding: 30px 48px 40px;
  width: 500px;
  max-width: 100%;
  margin-left: auto;
}

.payment-order-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.payment-order-img {
  margin-right: 15px;
}

.payment-order-profile {
  padding-bottom: 20px;
  border-bottom: 0.5px solid #acb4ba;
}

.profile-order-address {
  margin: 30px 0;
}

.order-address-title {
  margin-bottom: 10px;
}

.order-address-link img {
  margin-right: 8px;
}

.order-address-link {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #646667;
  margin-bottom: 10px;
}

.profile-order-price-details {
  margin: 30px 0 20px;
}

.order-price-details-rate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-price-details-rate-voucher {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-price-details-rate p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #646667;
  margin-bottom: 7px;
}

.order-price-details-rate-voucher p {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #008ef9;
  margin-bottom: 7px;
}

.order-price-details-rate:last-child {
  margin-top: 15px;
}

.order-cancellation-title {
  font-weight: 700;
}

.order-cancellation-discription {
  display: inherit;
  margin-bottom: 0;
}

.order-cancellation-discription a {
  font-weight: 700;
  color: #008ef9;
}

/* Book Modal Start */

.book-modal-dialog {
  width: 450px;
  max-width: 100%;
}

.book-modal-body {
  padding: 55px 92px 50px;
  text-align: center;
}

.book-modal-title {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  margin-bottom: 5px;
}

.book-modal-disctiption {
  color: #000000;
}

.book-modal-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-modal-input .form-control {
  padding: 5px 10px;
  width: 50px;
  max-width: 100%;
  height: 70px;
  margin-right: 10px;
}

.book-modal-input .form-control:last-child {
  margin-right: 0;
}

.book-modal-btn {
  margin: 40px 0 55px;
}

.book-modal-btn .btn {
  padding: 5.5px 86.5px;
}

.book-modal-change-no-title {
  display: inherit;
  margin-bottom: 0;
}

.book-modal-send-again {
  display: block;
  text-decoration: none;
  margin-bottom: 2px;
  color: #008ef9;
}

.book-modal-send-again:hover {
  color: #008ef9;
}

/* Book Modal End */

/* Payment Tabs End */

/* confirmation Tabs Start */

.confirmation-form-submit {
  padding: 53px 50px 74px;
}

.confirmation-subtim-title {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: #17146b;
  margin-bottom: 10px;
}

.confirmation-submit-domain {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #646667;
  margin-bottom: 0;
}

.confirmarion-form-plans {
  margin: 40px 0;
}

.confirmation-plans-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #646667;
  margin-bottom: 10px;
}

.confirmation-plans-discription a {
  font-weight: 700;
  color: #008ef9;
}

.confirmation-plans-input {
  margin-top: 20px;
}

.confirmation-plans-input .form-control {
  border: 0.5px solid #959da3;
  margin-bottom: 15px;
  padding: 11px 16px;
}

.confirmation-share-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.confirmation-form-btn {
  margin-top: 30px;
  padding: 10.5px 83.5px;
}

.plusone-confirmation-body .confirmation-george {
  padding: 55px 50px 45px;
}

.confirmation-george-title {
  margin: 40px 0 35px;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #17146b;
}

.confirmation-george-title span {
  font-weight: 700;
}

.confirmation-george-address {
  margin: 20px 0 30px;
}

.george-address-link {
  font-size: 16px;
  line-height: 22px;
}

.george-profile {
  align-items: start;
}

.george-address-link img {
  margin-right: 16px;
  width: 20px;
}

.confirmation-george-profile {
  padding: 18px 30px 18px 22px;
  border-radius: 10px 10px 0 0;
  border-bottom: 0;
}

.george-profile-img img {
  width: 80px;
}

.george-profile-img {
  margin-right: 14px;
}

.george-profile-price {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #292a2c;
  margin-bottom: 0;
}

.confirmation-george-chat {
  display: flex;
  align-items: start;
}

.george-profile-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.george-profile-discription {
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #959da3;
  margin-bottom: 12px;
}

.george-profile-chat {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #008ef9;
  text-align: center;
  border: 0.5px solid #acb4ba;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: 0;
  padding: 14px 0;
}

.george-profile-helth {
  margin: 20px 0 30px;
  display: flex;
  align-items: center;
}

.george-profile-helth img {
  margin-right: 15px;
}

.george-profile-helth-discription {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #292a2c;
  margin-bottom: 0;
}

.george-profile-btn .btn {
  padding: 10.5px 47px;
}

/* confirmation Tabs End */

/******** Errands Section Start ********/

/******** Scheduled Section Start *******/

.scheduled-section {
  padding: 51px 0 146px;
}

.scheduled-tabs {
  justify-content: center;
}

.scheduled-title {
  margin-bottom: 50px;
}

.scheduled-tabs .scheduled-nav-link {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #008ef9;
  padding: 23px 80.1px;
  transition: all 0.3s;
  border: none;
  border-bottom: 3px solid transparent;
}

.scheduled-nav-link::before {
  display: none;
}

.scheduled-tabs .nav-item {
  margin: 0 34px;
}

.scheduled-tabs .scheduled-nav-link:hover {
  color: #008ef9;
  transition: all 0.3s;
}

.scheduled-tabs .nav-item.show .nav-link,
.scheduled-tabs .nav-link.active {
  color: #008ef9;
  background-color: transparent;
  border-bottom-color: #008ef9;
  transition: all 0.3s;
}

.scheduled-tab-body {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 50px;
}

.scheduled-details {
  background-color: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px 10px 0 0;
  padding: 25px 80px 29px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scheduled-detiails-title {
  font-weight: 400;
  color: #959da3;
  margin-bottom: 10px;
}

.scheduled-details-link {
  color: #292a2c;
  align-items: start;
  line-height: 22px;
}

.scheduled-details-img img {
  width: 100px;
}

.scheduled-details-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #008ef9;
  margin-bottom: 0;
  margin-top: 8px;
}

.scheduled-view-details {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0.5px solid #acb4ba;
  border-radius: 0 0 10px 10px;
  border-top: 0;
  background-color: #fff;
  position: relative;
}

.scheduled-view-details a {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #008ef9;
  padding: 15px 0;
  position: relative;
  text-decoration: none;
  width: 100%;
  text-align: center;
}

.scheduled-view-details::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #acb4ba;
  top: 0;
  left: 50%;
}

.scheduled-details-content {
  margin-bottom: 30px;
}

.scheduled-new-moment a {
  width: 40px;
  height: 40px;
  background-color: #ffbb00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.schediled-new-moment-title {
  margin: 30px 0 0;
}

.scheduled-new-moment {
  margin: 50px 0 30px;
}

.sheduled-serach-bar .form-control {
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #17146b;
  padding: 24px 40px 24px 105px;
  background-image: url("../images/search.png");
  background-repeat: no-repeat;
  background-position: left 40px center;
}

.sheduled-serach-bar .form-control::placeholder {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #17146b;
}

.scheduled-view-details .btn {
  color: #ffffff;
  border-radius: 0 0 10px 10px;
}

/* Review Modal Start */

.review-modal {
  padding: 40px 50px 32px;
  text-align: center;
}

.review-modal .btn-close,
.details-modal .btn-close {
  position: absolute;
  top: 25px;
  right: 20px;
  background: none;
  opacity: 1;
  width: 30px;
  height: 30px;
  padding: 0;
}

.review-modal-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #17146b;
  margin-bottom: 4px;
  font-family: "PT Sans", sans-serif;
}

.review-modal-subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #17146b;
  margin-bottom: 0;
  font-family: "PT Sans", sans-serif;
}

.review-modal-heading {
  border-bottom: 0.5px solid #acb4ba;
  padding-bottom: 20px;
}

.review-modal-star {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-top: 10px;
}

.review-modal-star:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.review-modal-star:not(:checked)>label {
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 40px;
  color: #acb4ba;
}

.review-modal-star:not(:checked)>label:before {
  content: "★ ";
}

.review-modal-star>input:checked~label {
  color: #ffc700;
}

.review-modal-star:not(:checked)>label:hover,
.review-modal-star:not(:checked)>label:hover~label {
  color: #ffc700;
}

/* .rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
    color: #ffc700;
} */

.review-modal-img img {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.review-modal-tip-price {
  margin-bottom: 0;
  font-weight: 400;
  color: #17146b;
}

.review-modal-percentage {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 18px 0 15px;
}

.review-modal-percentage label {
  width: 80px;
  max-width: 100%;
  height: 40px;
  background-color: transparent;
  justify-content: center;
  border: 0.5px solid #acb4ba;
  border-radius: 50px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.review-perecenteage-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #17146b;
  margin-bottom: 0;
  font-family: "PT Sans", sans-serif;
}

.review-modal-amout {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffbb00;
  margin-bottom: 0;
}

.review-modal-percentage .form-check {
  margin-right: 20px;
}

.review-modal-percentage .form-check:last-child {
  margin-right: 0;
}

.review-modal-check-btn {
  padding: 30px 0;
  border-bottom: 0.5px solid #acb4ba;
}

.review-modal-percentage input:checked+label {
  background-color: #17146b;
  color: #fff;
}

.review-modal-btn .btn {
  padding: 10.5px 20px;
  width: 75.5%;
}

/* Review Modal End */

/* Details Modal Start */

.modal-content {
  border-radius: 10px;
}

.details-modal-dialog {
  width: 600px;
  max-width: 100%;
}

.details-modal-body {
  padding: 80px 48px 68px;
}

.details-modal-body {
  padding: 50px 50px 45px;
}

.details-modal-heading {
  margin-bottom: 20px;
}

.details-modal-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #17146b;
  margin: 20px 0 10px;
}

.checkin-btn {
  width: 100%;
}

.checkout-c-btn {
  font-weight: 700;
  color: #ffbb00;
  text-decoration: none;
}

.checkout-c-btn:hover {
  color: #ffbb00;
}

.details-modal-discription {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #292a2c;
  margin-bottom: 0;
}

.details-modal-refresh {
  display: block;
  text-align: end;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #008ef9;
  text-decoration: none;
}

.details-modal-refresh img {
  margin-right: 5px;
}

.details-modal-refresh:hover {
  color: #008ef9;
}

.details-modal-profile {
  background-color: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  padding: 24px 40px;
  margin-bottom: 20px;
}

.details-profile-img img {
  width: 100px;
}

.details-profile-img {
  margin-right: 19px;
}

.details-profile-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #17146b;
}

.details-mprofile-discription {
  margin: 10px 0 8px;
  font-size: 18px;
  line-height: 25px;
}

.details-modal-client-report i {
  font-size: 18px;
}

.details-modal-client-report {
  color: #292a2c;
  margin-bottom: 8px;
}

.details-modal-cancelled .how-work-profile-review {
  margin-bottom: 8px;
}

.details-mprofile-btn .btn {
  padding: 10.5px 19px;
}

.details-modal-more {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #008ef9;
  text-decoration: none;
  margin-top: 30px;
}

.details-modal-more i {
  margin-left: 8px;
}

.details-modal-price h5 {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: right;
  color: #292a2c;
}

.details-modal-price h5 i {
  margin-left: 17px;
}

.details-modal-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details-modal-cancelled {
  width: calc(100% - 119px);
}

/* Details Modal End */

/* Checkin Modal Start */

.checkin-modal-dialog {
  max-width: 480px;
}

.checkin-modal-body {
  padding: 0px 40px 44px;
}

.checkin-modal-title {
  width: 100%;
  text-align: start;
  margin: 0;
  display: flex;
  align-items: center;
  font-family: "PT Sans", sans-serif;
}

.checkin-modal-title i {
  font-size: 15px;
  margin-left: 5px;
}

.checkin-modal-subtitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #17146b;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
}

.checkin-modal-subtitle span {
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #959da3;
  margin-right: 8px;
}

.checkin-modal-profile-img {
  width: 40px;
  height: 40px;
  max-width: 100%;
  margin-right: 10px;
}

.checkin-modal-profile-details {
  width: calc(100% - 50px);
  max-width: 175px;
}

.checkin-modal-image {
  width: 111px;
  max-width: 100%;
}

.checkin-modal-profile {
  width: 100%;
  margin-right: 0;
}

.massage-momemt-map iframe {
  border-radius: 15px;
}

.checkin-modal-map {
  margin: 20px 0 25px;
}

.checkin-modal-address {
  max-width: 202px;
  margin: 0 auto 20px;
}

/* Checkin Modal End */

/* CheckOut Modal Start */

.checkout-modal-dialog {
  max-width: 450px;
}

.checkout-modal-body .checkin-modal-image {
  width: 155px;
}

.checkout-modal-body .checkin-modal-profile {
  width: calc(100% - 155px);
}

.checkout-modal-discription {
  color: #959da3;
  margin: 10px 0;
}

.checkout-modal-body .checkin-modal-subtitle span {
  line-height: 18px;
  margin-right: 0;
  color: #17146b;
  display: block;
  margin-bottom: 5px;
}

.checkout-modal-body .checkin-modal-subtitle {
  line-height: 18px;
  display: inherit;
  margin-bottom: 0;
}

.checkout-modal-body .massage-moment-user {
  display: flex;
  margin-top: 25px;
}

.checkout-modal-body .checkin-modal-profile-details {
  max-width: 100%;
}

/* CheckOut Modal End */

/******** Scheduled Section End *******/

/******* Coversation Section Start *********/

.conversation-section {
  padding: 80px 0 229px;
}

.bg-color {
  background-color: #f9fafb;
}

.conversation-content {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
}

.conversation-massage {
  margin-top: 50px;
  padding: 37px 50px 60px;
}

.conversation-message {
  margin-top: 50px;
  padding: 43px 33px 30px 66px;
}

.conversation-profile-img {
  display: inline-block;
  margin-right: 27px;
  width: 99px;
  max-width: 100%;
}

.coversation-profile-details {
  width: calc(100% - 126px);
}

.conversation-profile-img .p-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.coversation-profile-details ul {
  margin: 23px 0 0;
}

.conversation-profile-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 0;
  text-align: center;
  margin-top: 10px;
}

.conversation-profile {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.coversation-profile-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #292a2c;
  margin-bottom: 10px;
}

.conversation-profile-discription {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #646667;
  margin-bottom: 0;
}

.conversation-message-box .form-control {
  padding: 27px 13px 27px 45px;
}

.conversation-message-box textarea {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #646667;
}

.coversation-btn .btn {
  padding: 10.5px 53px;
}

.conversation-massage-box {
  position: relative;
  margin-bottom: 10px;
}

.coversation-massage-send {
  position: absolute;
  right: 30px;
  bottom: 22px;
  z-index: 1;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #d6d6d6;
  text-decoration: none;
}

.conversation-massage-box:hover .coversation-massage-send {
  color: #ffbb00;
}

.coversation-massage-send img {
  margin-left: 10px;
  width: 15px;
}

.conversation-massage .george-profile-helth-discription {
  font-size: 15px;
  line-height: 20px;
}

/******* Coversation Section End *********/

/****** message Section Start *******/

.massages-header {
  padding: 15.5px 30px;
}

.massage-heading {
  background: #d5edff;
  border-bottom: 0.5px solid #008ef9;
  display: flex;
  align-items: center;
  padding: 22.2px 0;
}

.massage-heading-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #17146b;
  margin-bottom: 0;
}

.massage-inbox,
.massage-print {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  position: relative;
}

.massage-inbox {
  padding-right: 17px;
}

.massage-print {
  padding-right: 37px;
}

/* .massage-inbox::before,
.massage-print::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 72.9px;
    background-color: #008EF9;
    right: 0;
    top: -25px;
} */

.message-profile-content,
.massage-moment-details {
  width: 360px;
  max-width: 100%;
  height: auto;
}

.massage-details {
  padding: 0 15px;
}

.message-profile-main-title {
  text-align: start;
  margin-bottom: 18px;
}

.message-profile-search .form-control {
  border-radius: 10px;
  background-color: #fff;
  border: gainsboro;
  padding: 9px 18px 9px 55px;
  background-image: url("../images/search-icon.png");
  background-repeat: no-repeat;
  background-position: left 18px center;
}

.message-profile-search {
  margin-bottom: 20px;
  position: relative;
}

.message-profile-search a {
  position: absolute;
  top: 7px;
  right: 14px;
  z-index: 1;
}

.message-profile-search .form-control::placeholder {
  color: #17146b;
}

.rk-message-user-detail-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 2px;
}

.rk-message-user-detail-item:last-child {
  margin-bottom: 0;
  display: inherit;
}

.rk-message-user-name {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #292a2c;
}

.rk-message-user-message-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #777986;
}

.rk-message-user-message-content,
.rk-message-user-send-icon {
  font-style: normal;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #646667;
}

.rk-message-user-send-icon {
  color: rgba(60, 60, 67, 0.6);
}

.message-profile-list {
  overflow-y: auto;
  padding: 20px 10px;
  background-color: #fff;
}

.message-profile-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding: 25px 11px 22px 22px;
  border-bottom: 0.5px solid #acb4ba;
}

.message-profile-link.active {
  background: #f7f7f7;
  border-radius: 10px;
  border-color: transparent;
}

.message-profile-link:last-child {
  margin-bottom: 0;
}

.message-user-img {
  margin-right: 20px;
}

.message-user-img img {
  width: 60px;
  height: 60px;
  border-radius: 7px;
  -o-object-fit: cover;
  object-fit: cover;
}

.rk-message-user-details {
  width: calc(100% - 80px);
}

.rk-message-user-detail-item .message-profile-receive-mass {
  width: 12px;
  height: 12px;
  background-color: #ffbb00;
  border-radius: 50%;
}

.rk-user-chat-header-name {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #17146b;
  margin-bottom: 7px;
}

.rk-user-chat-header-designation {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: rgba(60, 60, 67, 0.6);
  margin-bottom: 0;
}

.massage-inbox,
.massage-details,
.massage-print {
  background-color: #d5edff;
}

.massage-inbox {
  padding: 23px 17px 24px 30px;
  border-bottom: 0.5px solid #008ef9;
}

.massage-print {
  padding: 22px 37px 22.3px 30px;
  border-width: 0px 0.1px 0.5px 0.1px;
  border-style: solid;
  border-color: #008ef9;
}

.massage-details {
  padding: 24px 30px;
  border-bottom: 0.5px solid #008ef9;
}

.user-massage-chat {
  height: 100%;
  width: 800px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.rk-message-user-chat-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  /* height: 100%; */
  width: calc(100% - 720px);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: #acb4ba;
  height: auto;
}

.rk-user-chat-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 25px 30px 32px 25px;
  border-bottom: 1px solid rgba(210, 212, 218, 0.5);
}

.rk-user-chat-header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rk-user-chat-img {
  margin-right: 17px;
}

.rk-user-chat-img img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.rk-user-chat-option {
  cursor: pointer;
}

.dropstart .dropdown-toggle.rk-user-chat-option::before {
  display: none;
}

.rk-user-chat-input .form-control {
  padding: 14px 24px 14px 71px;
  border: none;
  background-color: #f9fafb;
  border-radius: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #959da3;
}

.rk-user-chat-input .form-control::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #959da3;
}

.rk-user-chat-input .message-profile-select-item {
  position: absolute;
  background-color: #ffbb00;
  border-radius: 50%;
  top: 10px;
  left: 21px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-profile-send-item {
  position: absolute;
  top: 10px;
  right: 20px;
}

.rk-user-chat-btn .btn {
  padding: 8px;
  border-radius: 8px;
}

.rk-user-chat-input {
  margin: 0 25px 40px;
  position: relative;
}

.rk-user-chat-file {
  margin: 0 20px;
}

.rk-user-chat-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 00 auto;

  flex: 1 00 auto;
  padding: 32px 0;
  height: 700px;
  min-height: auto;
  overflow-y: auto;
  margin-top: auto;
}

.rk-user-chat-body::-webkit-scrollbar {
  display: none;
}

.rk-user-chat-message:not(:last-child) {
  margin-bottom: 24px;
}

.rk-user-chat-message-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-left: 44px;
  margin-bottom: 14px;
  list-style: none;
}

.rk-user-chat-message-item {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #201f1e;
  padding: 25px 62px 25px 27px;
  background: #ffffff;
  max-width: 43.6%;
  margin-bottom: 0;
  background-color: #efefef;
  border-radius: 15px;
  position: relative;
}

.rk-user-chat-message-item::before {
  content: "";
  position: absolute;
  bottom: -10px;
  background-image: url("../images/message_icon_2.png");
  background-size: 21px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  left: -9px;
  transform: scaleX(-1) rotate(-22deg);
}

.rk-user-chat-message-sender {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 5px;
}

.rk-user-chat-message-sender-img img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.rk-user-chat-message-sender-time {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #808598;
  margin-left: 4px;
}

.rk-current-user-chat-message .rk-user-chat-message-list {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-left: 0;
  padding-right: 44px;
}

.rk-current-user-chat-message .rk-user-chat-message-sender {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding-left: 0;
  padding-right: 5px;
}

.rk-current-user-chat-message .rk-user-chat-message-sender-time {
  margin-right: 4px;
  margin-left: 0;
}

.rk-current-user-chat-message .rk-user-chat-message-item {
  background-color: #008ef9;
  border-radius: 15px;
  color: #ffffff;
  font-weight: 400;
}

.rk-user-chat-message-item .user-chat-time {
  position: absolute;
  bottom: 8px;
  right: 35px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.user-chat-time.user-receive-time {
  color: #646667;
}

.user-massage-read-time {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #acb4ba;
  margin-top: 22px;
}

.rk-current-user-chat-message .rk-user-chat-message-item::before {
  content: "";
  position: absolute;
  bottom: -8px;
  background-image: url("../images/message_icon.png");
  background-size: 21px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  right: -7px;
  left: unset;
  transform: rotate(-22deg);
}

.message-profile-chart-time {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 13px;
  padding-right: 100px;
  margin-bottom: 60px;
  line-height: 18px;
  text-align: right;
  color: rgba(60, 60, 67, 0.6);
}

.massage-box-content {
  display: flex;
  /* align-items: center; */
}

.massage-moment-details {
  padding: 30px 17px 10px 20px;
}

.massage-moment-subtitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #acb4ba;
  margin-bottom: 5px;
}

/****** message Section End *******/

/*========== Privacy & Sharing Section Start ===========*/

.privacy-sharing {
  width: 1084px;
  margin-left: 20px;
  padding: 80px 0;
  display: flex;
}

.privacy-sharing-body {
  width: calc(100% - 400px);
  height: calc(100vh - 76px);
  position: relative;
  overflow-y: scroll;
}

.privacy-sharing-body::-webkit-scrollbar {
  display: none;
}

.privacy-sharing-title {
  margin-bottom: 37px;
}

.privcacy-sharing-nav {
  border-bottom: 1px solid #acb4ba;
}

.privcacy-sharing-nav .nav-item {
  margin: 0 21px;
}

.privcacy-sharing-nav .nav-link {
  border: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #646667;
  padding: 0;
  padding-bottom: 15px;
  border-bottom: 3px solid transparent;
}

.privcacy-sharing-nav .nav-item.show .nav-link,
.privcacy-sharing-nav .nav-link.active {
  border-bottom: 3px solid #292a2c;
  background-color: transparent;
  color: #292a2c;
}

.privacy-sharing-body-title {
  font-family: "Open Sans", sans-serif;
  color: #292a2c;
  margin-bottom: 10px;
}

.privacy-sharing-tab-body {
  padding-top: 35px;
}

.privacy-sharing-body-discription {
  margin-bottom: 30px;
}

.privacy-request-data {
  background: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  padding: 27px 33px;
  position: relative;
  margin-bottom: 30px;
}

.privacy-request-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 33px;
  color: #292a2c;
  cursor: pointer;
}

.privacy-request-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 5px;
}

.privacy-request-discription {
  line-height: 25px;
}

.privacy-sharing-tabs {
  width: calc(100% - 484px);
}

.privacy-sharing-Committed {
  margin-left: 104px;
  width: 380px;
  max-width: 100%;
  background: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
  height: 180px;
  padding: 45px 32px;
}

.privacy-committed-title {
  line-height: 30px;
  margin-bottom: 20px;
}

.privacy-committed-discription {
  line-height: 25px;
}

.privacy-committed-discription a {
  color: #292a2c;
  font-weight: 700;
}

/*========== Privacy & Sharing Section End ===========*/

/*========= Privacy Sharing Request Start ========*/

.privacy-sharing-request {
  width: 540px;
  max-width: 100%;
  margin-left: 80px;
  padding: 80px 0;
}

.privacy-sharing-back {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #17146b;
  margin-bottom: 30px;
  font-size: 18px;
}

.privacy-sharing-request .privacy-sharing-body-title {
  margin-bottom: 30px;
}

.privacy-request-selector {
  margin-top: 50px;
}

.privacy-request-selector label {
  font-weight: 400;
  margin-bottom: 10px;
}

.privacy-request-selector select {
  border-radius: 8px;
  border: 0.5px solid #acb4ba;
  background-position: right 38px center;
  background-size: 15px;
}

.privacy-request-country {
  margin-bottom: 30px;
}

.privacy-request-selector select option {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #959da3;
}

.privacy-request-btn {
  background: #d6d6d6;
  border-radius: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding: 12px 94.4px;
}

/*========= Privacy Sharing Request End ========*/

/*====== Privacy Sharing Delete Account Start =====*/

.privacy-delete-account {
  background: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 12px;
  width: 595px;
  max-width: 100%;
  margin: 80px auto 34px;
  padding: 52px 47px;
}

.privacy-account-discription {
  color: #292a2c;
}

.privacy-sharing-about {
  margin-top: 40px;
}

.privacy-about-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 20px;
}

.privacy-sharing-about p {
  margin-bottom: 30px;
}

.privacy-sharing-about p:last-child {
  margin-bottom: 40px;
}

.privacy-sharing-account-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.privacy-sharing-account-btn a:first-child {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #008ef9;
  padding: 0;
  text-decoration: underline;
}

.privacy-sharing-account-btn a {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.privacy-sharing-account-btn a:last-child {
  padding: 12px 78.7px;
  margin-right: 20px;
}

.privacy-delete-account-title {
  margin: 13px 0 3px;
  line-height: 48px;
}

.privacy-deactivate {
  border: 1px solid #ffbb00;
  border-radius: 50px;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffbb00;
  margin: 30px 0;
  padding: 13px;
}

.privacy-deactivate:hover {
  color: #ffbb00;
  opacity: 0.7;
}

.privacy-delete-btns {
  border-top: 0.5px solid #acb4ba;
  padding-top: 30px;
}

.privacy-delete-btns a:last-child {
  padding: 12px 53px;
  margin-right: 20px;
}

/*====== Privacy Sharing Delete Account End =====*/

/*======== Deactivate Header Start =========*/

.deactivate-tabs-header {
  display: flex;
  align-items: center;
  padding: 23.6px 30px;
  border-bottom: 1px solid #008ef9;
}

.deactivate-tabs-header .information-nav {
  padding: 0;
  width: calc(100% - 117px);
}

.deactivate-tab-body .progress-bar {
  background-color: #008ef9;
  border-radius: 0 5px 5px 0;
}

.deactivate-tabs-header .nav-item {
  margin: 0 90px;
}

.deactivate-tab-content {
  padding: 130px 0;
}

.deactivate-account {
  margin: 0 auto;
  border-radius: 10px;
}

.deactivate-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19.5px 0;
  border-bottom: 0.5px solid #acb4ba;
  margin: 0;
}

.deactivate-checkbox input {
  width: 25px;
  height: 25px;
  border: 1.5px solid #acb4ba;
  margin: 0;
}

.deactivate-checkbox label {
  max-width: 400px;
  line-height: 20px;
  color: #292a2c;
  margin: 0;
}

.deactivate-checkbox .form-check-input:checked {
  background-color: #008ef9;
  border-color: #008ef9;
}

.deactivate-check-discription {
  border: 1px solid #008ef9;
  border-radius: 10px;
  padding: 0 24px;
  margin: 0;
  line-height: 20px;
  color: #000;
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.deactivate-other-reason {
  padding-bottom: 25px;
}

.deactivate-check-discription-show {
  opacity: 1;
  visibility: visible;
  height: 50px;
  transition: all 0.3s ease-in-out;
}

.d-confirm-title {
  margin-bottom: 20px;
}

.d-confirm-email {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #292a2c;
  margin-bottom: 50px;
}

.d-confirm-email:nth-child(3),
.d-confirm-email:nth-child(4) {
  display: flex;
  align-items: start;
  margin-bottom: 45px;
  line-height: 27px;
}

.d-confirm-email:nth-child(4) {
  margin-bottom: 20px;
}

.d-confirm-email span {
  margin-right: 20px;
  font-size: 25px;
  color: #4e4e4e;
}

.d-final-title {
  margin: 30px 0 10px;
}

.deactivate-account .d-final-discription {
  max-width: 450px;
  margin: 0 auto 30px;
}

.d-final-btn {
  padding: 12px 73.4px;
}

/*======== Deactivate Header End =========*/

/*============= How Work Section Start ============*/

.howwork-video-content {
  padding: 80px;
}

.howwork-video-item {
  width: 500px;
  max-width: 100%;
  margin-bottom: 50px;
}

.howwork-video-title {
  margin-bottom: 10px;
}

.howwork-video-discription {
  margin-bottom: 26px;
}

.howwork-video video {
  border-radius: 30px;
}

.howwork-video {
  position: relative;
}

.howwork-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.howwork-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d6d6d6;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.howwork-video-icon-hide {
  opacity: 0;
}

.howwork-questions {
  background: #ffffff;
  border: 0.5px solid #959da3;
  border-radius: 12px;
  padding: 46px 28px;
  margin-bottom: 50px;
}

.howwork-litora {
  width: 400px;
  max-width: 100%;
}

.howwork-questions-title {
  line-height: 35px;
  margin-bottom: 30px;
}

.howwork-questions-points {
  display: flex;
  margin-bottom: 30px;
}

.howwork-comment-question {
  border-bottom: 0.5px solid #acb4ba;
  padding-bottom: 30px;
}

.howwork-questions-subtitle,
.howwork-questions-discription {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #292a2c;
  margin-bottom: 5px;
}

.howwork-questions-discription {
  margin-bottom: 0;
  font-weight: 400;
}

.howwork-questions-img {
  margin-right: 30px;
  width: calc(100% - 291px);
}

.howwork-question-details {
  width: 216px;
  max-width: 100%;
}

.howwork-questions .accordion-button {
  padding: 20px 0;
  margin: 0;
}

.howwork-questions .accordion-button:not(.collapsed) {
  color: #292a2c;
}

.howwork-questions .accordion-body {
  padding: 0 0 27px 0;
}

.howwork-questions .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.howwork-questions .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.howwork-turpis .howwork-questions-title {
  margin-bottom: 20px;
}

.howwork-turpis-btn .btn {
  margin: 0;
  width: auto;
  display: inline-block;
  padding: 13px 108.4px;
  margin-bottom: 20px;
}

.howwork-turpis-img {
  margin-bottom: 28px;
}

/*============= How Work Section Start ============*/

/*========== Safety Center Start ===========*/

.safety-center-content .privacy-sharing-title {
  margin-bottom: 10px;
}

.safety-center-content .privacy-committed-title {
  font-weight: 400;
  max-width: 500px;
  margin: 0;
}

.safety-center {
  width: 1119px;
  max-width: 100%;
}

.safety-center-content {
  width: calc(100% - 487px);
}

.safety-center-emergency {
  padding: 44px 66px;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0.5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 30px 0 75px;
}

.safety-center-emergency .privacy-committed-title:first-child {
  font-weight: 700;
  color: #e31103;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 20px;
}

.safety-tips-title {
  margin-bottom: 30px;
}

.safety-tips-item {
  display: flex;
  align-items: center;
}

.safety-tips-link {
  display: inline-block;
  margin-right: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 0.5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 163px;
  max-width: 100%;
}

.safety-tips-body {
  padding: 10px 36px 24px 20px;
}

.safety-tips-body .section-discription {
  text-align: left;
  color: #292a2c;
}

.safety-tips-body .section-discription:first-child {
  font-weight: 600;
}

.safety-contact {
  width: 343px;
  margin-left: 70px;
}

.safety-contact .privacy-committed-title {
  line-height: 30px;
  margin-bottom: 10px;
  font-size: 20px;
}

.safety-contact .privacy-committed-discription {
  color: #292a2c;
}

.safety-contact .howwork-turpis-btn .btn {
  padding: 13px 55.8px;
  margin: 15px 0;
}

.safety-feedback {
  line-height: 25px;
  text-align: left;
  color: #292a2c;
}

.safety-center-emergency p span {
  margin-right: 15px;
}

.contact-support-content .section-discription {
  line-height: 25px;
  text-align: left;
  color: #292a2c;
}

.contact-support-content .section-discription a {
  font-weight: 700;
  color: #292a2c;
}

.contact-support-img {
  margin: 30px 0 50px;
}

.contact-support-content .safety-tips-title {
  color: #17146b;
  font-weight: 700;
}

/*========== Safety Center End ===========*/

.privacy-sharing-body .po-sharing-sidebar-icon {
  font-size: 30px;
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 1;
}

/*========== Contact Support Section Start ===========*/

.contact-emergencie-title {
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 10px;
}

.contact-emergencie-title span {
  margin-right: 17px;
}

.contact-emergencie small {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #959da3;
  margin: 20px 0 0;
  display: inline-block;
}

.contact-emergencie small a {
  color: #292a2c;
  font-weight: 700;
}

.contact-emergencie {
  margin-bottom: 50px;
}

.contact-support-form .po-sharring-setting-title {
  margin-bottom: 10px;
}

.contact-support-massage-box {
  margin: 50px 0 40px;
  max-width: 500px;
}

.contact-support-massage-box label {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #292a2c;
  margin-bottom: 10px;
}

.contact-support-massage-box .form-control {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #646667;
  padding: 15px 16px;
}

.contact-support-details-title {
  color: #292a2c;
  margin-bottom: 10px;
  text-decoration: none;
}

.contact-support-details label {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 20px;
}

.contact-support-details .form-control {
  margin-bottom: 13px;
}

.contact-support-btn {
  padding: 12px 119.5px;
  margin-top: 7px;
}

/*========== Contact Support Section End ===========*/

/*======== Get help Start ================*/

.get-help {
  margin-left: 80px;
}

.get-help .safety-contact {
  margin-left: 133px;
}

.get-help-input {
  position: relative;
}

.get-help-input-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.get-help-input input {
  background: #ffffff;
  border: 1px solid #008ef9;
  border-radius: 50px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #acb4ba;
  padding: 10px 20px 11px 55px;
}

.get-help-input input::placeholder {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #acb4ba;
}

.get-help .privacy-sharing-title {
  margin-bottom: 30px;
}

.get-help-recommended {
  margin: 50px 0 45px;
}

.get-help-recommended .refer-work-item-title {
  font-weight: 700;
  color: #17146b;
}

.get-help-quick-link {
  background: #ffffff;
  border: 0.5px solid #acb4ba;
  border-radius: 10px;
}

.get-help-recommended .col {
  padding: 0 10px;
}

.get-help-quick-body {
  padding: 23px 50px 23px 24px;
}

.get-help-quick-body .section-discription {
  font-weight: 600;
  text-align: left;
  color: #959da3;
  margin-bottom: 19px;
}

.get-help-quick-body .po-sharring-setting-title {
  margin-bottom: 10px;
}

.get-help-quick-body .get-help-quick-discri {
  font-weight: 400;
  line-height: 25px;
  color: #646667;
  margin-bottom: 0;
}

.get-help-quick-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12.5px 20px;
  border-top: 0.5px solid #acb4ba;
}

.get-help-quick-footer .section-discription {
  font-weight: 600;
  color: #292a2c;
}

.get-help-quick-footer a {
  color: #292a2c;
}

.get-help-guides-title {
  margin-bottom: 12px;
}

.get-help-guides-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #acb4ba;
  padding: 27.5px 0;
}

.get-help-guides-link a {
  color: #292a2c;
}

.get-help-explore {
  margin-top: 60px;
  background-color: #0b0872;
  border-radius: 10px;
  padding: 30px 40px 45px;
}

.get-help-explore .refer-work-item-title {
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 25px;
}

.get-help-explore-item .safety-tips-body {
  padding: 20px 30px 24px 20px;
}

.get-help-explore-item .btn {
  margin-top: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #292a2c;
}

.get-help-explore-item .btn i {
  margin-left: 7px;
}

.guides-sample-subtitle {
  color: #959da3;
  margin-bottom: 10px;
}

.guide-sample-img {
  margin-bottom: 64px;
}

.guide-sample-img .section-discription {
  text-align: left;
  color: #292a2c;
  margin-top: 30px;
}

.guide-sample-rules .section-discription {
  line-height: 25px;
  color: #292a2c;
  text-align: left;
}

.guides-sample .privacy-sharing-tabs {
  width: calc(100% - 452px);
}

.guides-sample .safety-contact {
  margin-left: 61px;
  margin-top: 75px;
}

.guide-rules-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  text-decoration-line: underline;
  color: #292a2c;
}

.guide-sample-rules {
  margin-bottom: 80px;
}

.guide-rules-item {
  padding: 30px 0;
  margin: 30px 0;
  border-width: 0.5px 0;
  border-style: solid;
  color: #acb4ba;
}

/*======== Get help End ================*/

/*========= Give feedback Start ==========*/

.give-feedback-main-title {
  font-size: 40px;
  margin-bottom: 10px;
}

.section-discription-two {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #292a2c;
}

.giv-feedback-heading .section-discription-two:first-child {
  margin-bottom: 30px;
}

.giv-feedback-heading .section-discription-two a {
  font-weight: 700;
  color: #292a2c;
}

.give-feedback-form {
  margin-top: 30px;
}

.give-feedback-form .section-discription-two {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #292a2c;
  margin-bottom: 20px;
}

.give-feedback-select {
  margin-bottom: 30px;
}

.give-feedback-select .form-select {
  background-size: 20px;
  background-position: right 25px center;
}

/*========= Give feedback End ==========*/

/*========= Contact Us  Section Start ==========*/

.contact-us-inbox {
  width: 360px;
  max-width: 100%;
}

.contact-us-inbox-body {
  padding: 20px 10px;
  height: 100vh;
}

.contact-profile-link {
  align-items: start;
  padding: 23px 10px 12px 20px;
}

.contact-profile-link .message-user-img {
  margin-right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  max-width: 100%;
  background-color: #008ef9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-profile-link .rk-message-user-message-content,
.contact-profile-link .rk-message-user-send-icon {
  font-size: 15px;
  line-height: 20px;
}

.contact-massage-time {
  justify-content: end;
  margin-top: 10px;
}

.contact-us-content {
  display: flex;
}

.contact-us-messenger {
  width: calc(100% - 360px);
}

.contact-us-content .massage-print {
  border-width: 0px 0 0.5px 0.5px;
}

.contact-massage-body {
  height: 100vh;
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: #acb4ba;
  background-color: #fff;
  padding: 80px 0 42px;
}

.contact-massage-box {
  width: 800px;
  margin: 0 auto;
}

.contact-massage-title-date {
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #646667;
  margin-bottom: 80px;
}

.contact-massage-profile {
  background-color: #008ef9;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.contact-massage-link {
  display: flex;
}

.contact-massage-details .rk-message-user-name {
  font-weight: 600;
  margin-bottom: 30px;
}

.contact-massage-details {
  padding-top: 13px;
  width: calc(100% - 65px);
}

.comtact-massage-time {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #acb4ba;
  margin-left: 15px;
}

.contact-massage-discription {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #292a2c;
  margin-bottom: 30px;
}

.contact-massage-discription:last-child {
  margin-bottom: 0;
}

.contact-massage-input {
  margin: 0;
  margin-top: 50px;
}

.contact-massage-input .message-profile-select-item {
  width: 25px;
  height: 25px;
}

.contact-massage-input .form-control {
  padding: 14px 24px 14px 60px;
}

/*========= Contact Us  Section End ==========*/

/****** Footer Start ******/

.footer {
  background-color: #ebf7ff;
}

.footer-title {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: #17146b;
  margin-bottom: 14px;
}

.footer-discription {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  color: #17146b;
  margin-bottom: 25px;
}

.footer-btn {
  padding: 12px 71px;
}

.footer-img img {
  position: relative;
  margin-top: -45px;
}

.footer-empty {
  background-color: #c4e2f9;
  height: 35px;
  position: relative;
  z-index: 1;
  margin-top: -18px;
}

.footer-copyright {
  background-color: #1e1c60;
  padding: 19.5px 0;
}

.footer-app-store a {
  margin-right: 20px;
}

.footer-copyright-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-copyright-discription {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f6fbfc;
}

/****** Footer End ******/

/* ***************** Home Page End  ******************** */

/* ***************** Responsive Start  ******************** */

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1224px;
  }

  .privacy-data-header .container {
    max-width: 1884px;
  }
}

@media (max-width: 1599px) {
  .mission-section::before {
    height: calc(100vh - -5px);
  }

  .co-login-form {
    margin-left: unset;
    margin-right: auto;
  }

  .privacy-sharing {
    width: 908px;
    margin-left: 70px;
  }

  .privacy-sharing-Committed {
    margin-left: 70px;
  }

  .howwork-video-content {
    padding: 60px;
  }

  .howwork-litora {
    margin-left: auto;
  }

  .get-help-quick-body {
    padding: 23px 15px;
  }

  .policy-details-content {
    padding: 40px;
  }

  .policy-contact {
    padding-top: 50px;
  }
}

@media (max-width: 1199px) {
  .login-body-content {
    padding: 30px 75px 30px;
  }

  .faq-section {
    padding: 195px 0 100px;
  }

  .footer-content .col-xl-4 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .how-work-slider-item {
    padding: 29px 8px 29px 8px;
  }

  .r-book-progress {
    width: 650px;
  }

  .r-book-logo {
    margin-right: 100px;
  }

  .payment-book-card-details {
    padding: 35px 25px 40px;
  }

  .plusone-payment-order {
    padding: 30px 25px 40px;
  }

  .nav-tabs .nav-item {
    margin: 0 20px;
  }

  .information-logo {
    margin-right: 60px;
  }

  .information-tab-progress {
    width: 750px;
  }

  .massage-box-content {
    display: flex;
    flex-wrap: wrap;
  }

  .message-profile-content {
    width: 35%;
  }

  .rk-message-user-chat-content {
    width: 65%;
  }

  .massage-moment-details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .massage-moment-heading {
    /* margin-right: 30px; */
  }

  .message-po-profile-heading {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    /* Body - Main Color */
  }

  .message-moment-heading h5 {
    width: 100%;
  }

  .massage-momemt-map {
    margin: 0 15px 30px;
  }

  .massage-update-moment {
    max-width: 195px;
    margin: 0 30px 0 0px;
  }

  .massage-moment-address {
    padding: 0;
    margin: 0 30px;
    border-width: 0;
  }

  .co-login-form {
    margin-left: unset;
    margin-right: auto;
    width: 500px;
    padding: 27px 15px;
  }

  .co-login-input-box {
    flex-direction: column;
    align-items: start;
    margin-bottom: 30px;
  }

  .co-login-form-content {
    padding-top: 150px;
  }

  .co-login-select-country {
    margin-right: 0;
    width: 360px;
    margin-bottom: 20px;
  }

  .co-login-btn {
    width: 360px;
  }

  .confirmed-address .massage-moment-heading {
    margin-right: 0;
  }

  .confirmed-section .massage-moment-profile {
    width: calc(100% - 180px);
    margin-right: 24px;
  }

  .confirmed-address {
    padding: 55px 42px 66px;
  }

  .confirmed-address .massage-momemt-map {
    margin: 0 0 25px;
  }

  .plusone-profile-price {
    padding: 32px 20px 44px;
  }

  .errands-map-box {
    padding: 40px 0 0 10px;
  }

  .privacy-sharing-tabs {
    width: 100%;
  }

  .privacy-sharing {
    width: 554px;
    margin-left: 35px;
    display: inherit;
  }

  .privacy-sharing-Committed {
    margin-left: 0;
  }

  .privacy-sharing-request {
    margin-left: 50px;
  }

  .deactivate-tabs-header .nav-item {
    margin: 0 69px;
  }

  .howwork-litora {
    margin: 0 auto;
  }

  .safety-contact {
    margin-left: 0;
    margin-top: 50px;
  }

  .safety-center-emergency {
    padding: 44px 40px;
    margin: 30px 0 49px;
  }

  .guides-sample .privacy-sharing-tabs {
    width: 100%;
  }

  .guides-sample {
    padding: 40px 0;
  }

  .contact-massage-box {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .navbar-nav {
    background-color: #ebf7ff;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .nav-link {
    padding: 15px 0;
  }

  .navbar-nav .btn {
    margin-top: 15px;
  }

  .login-body-content {
    margin: 50px auto;
  }

  .login-img img {
    height: 630px;
    width: 100%;
    object-fit: cover;
  }

  .event-content {
    width: 100%;
  }

  .event-item {
    margin-bottom: 40px;
  }

  .event-content .row {
    justify-content: space-evenly;
  }

  .po-trending-moments-section {
    padding: 40px 0 65px;
  }

  .how-work-list {
    padding: 40px 30px;
    width: 100%;
  }

  .header-inputs {
    width: 270px;
  }

  .how-work-item {
    text-align: center;
    padding: 0;
    margin-bottom: 40px;
  }

  .header-dropdown-title {
    margin-right: 20px;
  }

  .po-scrolling-text-label:after {
    right: 0;
  }

  .po-scrolling-text-label:before {
    left: 0;
  }

  .mission-details-discription {
    font-size: 20px;
    line-height: 30px;
  }

  .faq-title {
    margin-bottom: 40px;
  }

  .footer-app-store a {
    margin-right: 10px;
  }

  .footer-title {
    font-size: 28px;
    line-height: 34px;
  }

  .footer-discription {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 19px;
  }

  .footer-btn {
    padding: 12px 35px;
  }

  .footer-content .col-xl-4 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .r-book-progress {
    width: 500px;
  }

  .plusone-payment-order {
    width: 100%;
    margin: 50px 0 0;
  }

  .price-tab-body {
    padding-bottom: 100px;
  }

  .information-nav {
    display: inherit;
    padding: 20px 15px 27px;
  }

  .information-logo {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .r-book-logo {
    margin-right: 60px;
  }

  .nav-tabs .nav-item {
    margin: 0 17px;
  }

  .massage-moment-heading {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .co-login-form-content {
    padding-top: 60px;
  }

  .co-login-form {
    width: 100%;
    text-align: center;
  }

  .co-login-input-box {
    align-items: center;
  }

  .co-login-discription {
    max-width: 500px;
    margin: 0 auto 50px;
  }

  .co-login-logo {
    text-align: start;
  }

  .confirmed-helth p {
    margin: 0 auto;
  }

  .confirmed-section {
    padding: 51px 0 70px;
  }

  .confirmed-section .order-address-link {
    font-size: 16px;
    line-height: 23px;
  }

  .confirmed-address {
    margin: 0 auto 30px;
  }

  .confirmed-chat {
    margin: 0 auto 20px;
  }

  .confirmed-section .massage-moment-image {
    width: 120px;
    margin-left: auto;
  }

  .confirmend-title img {
    width: 17px;
  }

  .forgot-pass-content {
    margin: 70px auto 0;
  }

  .policy-contact {
    margin: unset;
    max-width: 100%;
    padding-top: 35px;
  }

  .refer-main-title {
    font-size: 30px;
    line-height: 38px;
  }

  .refer-discription {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 27px;
  }

  .refer-input {
    margin-top: 36px;
  }

  .refer-work-content {
    margin: 40px 0;
    padding: 40px 0;
  }

  .refer-work-title {
    margin-bottom: 35px;
  }

  .refer-work-item {
    margin-bottom: 30px;
  }

  .refer-section {
    padding: 50px 0 70px;
  }

  .po-sharing-sidebar {
    position: absolute;
    top: 77px;
    left: -405px;
    transition: all 0.5s ease-in-out;
    z-index: 1;
  }

  .po-sharing-sidebar-show {
    left: 0;
    transition: all 0.5s ease-in-out;
  }

  .selecting-profile-heading {
    margin: 0 0 37px 0;
  }

  .plusone-profile-price {
    margin: 30px 0;
  }

  .selecting-profile-footer {
    padding: 11.5px 0;
  }

  .selecting-profile img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .selecting-profile-price-title {
    font-size: 23px;
    line-height: 34px;
    margin-right: 16px;
  }

  .price-content {
    width: 100%;
  }

  .price-tab-body {
    padding: 50px 0;
  }

  .price-helth {
    width: 100%;
    margin-bottom: 30px;
  }

  .policy-section {
    padding: 40px 0;
  }

  .terms-services {
    width: 100%;
    margin-bottom: 30px;
  }

  .view-profile-details-title {
    font-size: 28px;
    line-height: 37px;
  }

  .terms-discription {
    margin: 22px 0;
  }

  .terms-rules-title {
    margin-bottom: 20px;
  }

  .view-profile-box {
    margin: 0 25px 0 20px;
  }

  .view-profile-details-content {
    width: calc(100% - 415px);
  }

  .view-profile-section {
    padding: 80px 0;
  }

  .datepicker-inline {
    margin: 0 auto;
  }

  .erratds-caledar-title {
    text-align: center;
  }

  .timeset-times {
    justify-content: center;
  }

  .errands-map-btn {
    text-align: center;
  }

  .errands-map {
    margin-top: 40px;
  }

  .timeset-body {
    padding: 80px 0 83px;
    width: 100%;
  }

  .privacy-sharing-body {
    width: 100%;
  }

  .privacy-sharing {
    width: 100%;
    margin-left: 0;
    padding: 70px 34px;
  }

  .privacy-sharing-request {
    margin-left: 0;
    width: 100%;
    padding: 50px;
  }

  .deactivate-tabs-header .nav-item {
    margin: 0 40px;
  }

  .deactivate-tab-content {
    padding: 70px 0;
  }

  .howwork-video-item {
    width: 100%;
  }

  .contact-support-massage-box {
    max-width: 100%;
  }

  .privacy-sharing-hemburg {
    top: -44px;
    left: -7px;
  }

  .contact-us-inbox {
    position: absolute;
    z-index: 1;
    left: -374px;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    box-shadow: 1px 0 21px #d5d5d5;
  }

  .contact-us-messenger {
    width: 100%;
    position: relative;
  }

  .contact-us-inbox-show {
    left: 0;
    transition: all 0.5s ease-in-out;
  }

  .contact-us-content .massage-print {
    padding: 22px 37px 22.3px 75px;
  }

  .contact-massage-btn {
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 5px;
    font-size: 23px;
  }

  .policy-details-content {
    width: 100%;
    padding: 60px 20px;
  }

  .policy-section .po-sharing-sidebar-icon {
    top: -53px;
  }

  .policy-rules {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .conversation-section {
    padding: 30px 0;
  }

  .plusone-heading-title {
    font-size: 25px;
    line-height: 30px;
  }

  .conversation-massage {
    margin-top: 25px;
  }

  .plusone-profile-price {
    padding: 25px 15px;
  }

  .conversation-profile {
    flex-direction: column;
    margin-bottom: 25px;
  }

  .conversation-profile-img {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .conversation-massage-box .form-control {
    padding: 10px 15px;
  }

  .coversation-btn .btn {
    padding: 5.5px 47px;
    width: 100%;
  }

  .btn {
    font-size: 16px;
  }

  .login-img img {
    height: 460px;
  }

  .login-body-content {
    padding: 30px 20px;
  }

  .login-logo {
    width: 130px;
  }

  .login-btn .btn {
    padding: 13px 80.5px;
    width: 100%;
  }

  .header-content {
    flex-wrap: wrap;
  }

  .header-inputs {
    width: 100%;
    margin: 20px 0;
    order: 3;
  }

  .po-landing-section {
    padding: 35px 0 100px;
  }

  .po-landing-content__inner {
    padding: 30px 15px;
    width: 100%;
  }

  .po-landing-section-title {
    font-size: 30px;
    line-height: 38px;
    max-width: 100%;
  }

  .signup-body-content .signup-body-title {
    line-height: 28px;
    font-size: 26px;
  }

  .po-scrolling-text-label:before {
    left: -60px;
  }

  .po-scrolling-text-label:after {
    right: -60px;
  }

  .po-landing-form-input-group {
    display: inherit;
  }

  .po-landing-form-input {
    margin-right: 0;
    width: 100%;
  }

  .po-landing-form-input-group .btn {
    width: 100%;
    margin-top: 15px;
  }

  .po-service-list {
    justify-content: center;
  }

  .po-service-item {
    margin-bottom: 15px;
  }

  .po-service-item:last-child {
    margin-bottom: 0;
  }

  .event-section {
    padding: 50px 0 20px;
  }

  .section-title {
    font-size: 24px;
    line-height: 28px;
  }

  .po-trending-slide {
    margin: 0 7px;
  }

  .event-item {
    margin-bottom: 25px;
  }

  .po-trending-moments-title {
    margin-bottom: 30px;
  }

  .how-work-heading-img {
    margin-right: 0;
  }

  .features-section {
    padding: 40px 0 115px;
  }

  .mission-details-title {
    font-size: 28px;
    line-height: 38px;
  }

  .faq-title {
    margin-bottom: 20px;
  }

  .header-logo {
    order: 1;
    width: 107px;
  }

  .header-dropdown {
    order: 2;
  }

  .how-work-section {
    padding: 50px 0;
  }

  .how-work-slider-title {
    margin-bottom: 30px;
  }

  .mission-details-discription {
    font-size: 16px;
    line-height: 28px;
  }

  .accordion-button {
    font-size: 18px;
    line-height: 27px;
    padding: 27px 8px 27px 0;
  }

  .accordion-button::after {
    background-size: 14px;
  }

  .faq-section {
    padding: 195px 0 50px;
  }

  .footer-title,
  .footer-discription {
    text-align: center;
  }

  .footer-btn {
    width: 100%;
  }

  .footer-img img {
    margin-top: 0;
  }

  .footer-empty {
    margin-top: 0;
  }

  .footer-copyright-content {
    flex-direction: column;
  }

  .footer-copyright-logo {
    margin-right: 16px;
  }

  .footer-copyright-discription {
    margin: 15px 0;
  }

  .footer-copyright {
    padding: 15px 0;
  }

  .faq-discription {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .scheduled-tabs .nav-item {
    margin: 0;
  }

  .scheduled-tab-body {
    width: 100%;
    padding: 20px 15px 0;
  }

  .scheduled-details {
    padding: 20px 15px;
  }

  .profile-order-address {
    margin: 0;
  }

  .scheduled-new-moment {
    margin: 30px 0 15px;
  }

  .schediled-new-moment-title {
    margin: 18px 0 0;
    font-size: 22px;
    line-height: 28px;
  }

  .sheduled-serach-bar .form-control::placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  .sheduled-serach-bar .form-control {
    font-size: 16px;
    line-height: 25px;
    padding: 12px 20px 11px 60px;
    background-size: 18px;
    background-position: left 24px center;
  }

  .scheduled-section {
    padding: 50px 0;
  }

  .scheduled-title {
    margin-bottom: 25px;
  }

  .details-modal-profile {
    padding: 16px;
  }

  .george-profile {
    flex-direction: column;
  }

  .details-profile-img {
    margin: 0 auto 20px;
  }

  .details-modal-cancelled {
    width: 100%;
  }

  .details-modal-title {
    font-size: 22px;
    line-height: 26px;
  }

  .details-mprofile-discription {
    font-size: 16px;
  }

  .details-modal-dialog {
    width: auto;
  }

  .review-modal {
    padding: 20px 15px;
  }

  .details-modal-body {
    padding: 20px 15px;
  }

  .coversation-profile-details {
    width: 100%;
  }

  .login-modal-body {
    padding: 30px 20px;
  }

  .how-work-item-img {
    width: 80px;
    height: 80px;
  }

  .how-work-item-img img {
    width: 40px;
  }

  .r-book-heading {
    display: inherit;
    padding: 15px 20px;
  }

  .r-book-progress {
    margin-top: 20px;
  }

  .r-book-body {
    padding: 30px 0 50px;
    flex-direction: column;
  }

  .p-method-title {
    font-size: 25px;
    line-height: 30px;
  }

  .p-method-headline {
    margin-bottom: 30px;
  }

  .p-method-body {
    padding: 15px;
  }

  .price-tab-body {
    padding: 40px 0;
  }

  .payment-card-img {
    margin-right: 30px;
    width: 35px;
  }

  .plusone-payment-heading {
    align-items: center;
    margin-bottom: 30px;
  }

  .p-method-profile-address {
    margin: 20px 0px;
  }

  .payment-card-name h5 {
    font-size: 16px;
    line-height: 24px;
  }

  .plusone-payment-fill .plusone-profile-heading {
    display: inherit;
    text-align: center;
  }

  .r-book-payment {
    padding: 20px;
    margin-bottom: 20px;
  }

  .r-book-payment-btn .btn {
    margin-top: 15px;
  }

  .massage-moment-heading {
    flex-direction: column;
  }

  .checkin-modal-body {
    padding: 20px;
  }

  .checkin-modal-profile {
    width: 100%;
    margin-bottom: 30px;
  }

  .checkin-modal-title,
  .checkin-modal-subtitle,
  .checkin-modal-body .massage-moment-user {
    justify-content: center;
  }

  .review-modal-percentage label {
    width: 65px;
  }

  .nav {
    flex-direction: column;
    align-items: start;
    padding: 0 30px;
  }

  .scheduled-tabs .scheduled-nav-link {
    width: 100%;
  }

  .nav-tabs .nav-item {
    margin: 10px 0;
    width: 100%;
  }

  .information-tab-progress {
    width: 100%;
    flex-direction: column;
    align-items: start;
  }

  .information-form-content {
    padding-top: 40px;
  }

  .information-form-box {
    display: inherit;
  }

  .information-form-back {
    margin-bottom: 20px;
    display: block;
  }

  .information-tab-body {
    padding-bottom: 40px;
  }

  .information-form {
    width: 100%;
    padding: 25px 20px;
  }

  .information-form-input {
    margin-bottom: 15px;
  }

  .information-form-input label {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 8px;
  }

  .information-progress-title {
    width: 100%;
    margin-bottom: 10px;
  }

  .information-profile-title {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 18px;
  }

  .progress {
    width: 100%;
  }

  .profile-modal-body {
    padding: 25px 20px;
  }

  .profile-modal-title {
    font-size: 27px;
    line-height: 34px;
    padding: 0;
  }

  .profile-modal-name {
    font-size: 25px;
    line-height: 33px;
    margin-bottom: 4px;
  }

  .profile-modal-country {
    font-size: 18px;
    line-height: 23px;
  }

  .message-profile-content,
  .rk-message-user-chat-content,
  .user-massage-chat {
    width: 100%;
  }

  .rk-current-user-chat-message .rk-user-chat-message-list {
    padding-right: 20px;
  }

  .rk-user-chat-message-item {
    padding: 15px 15px 30px;
    max-width: 65%;
  }

  .rk-user-chat-message-item .user-chat-time {
    right: 12px;
  }

  .message-profile-chart-time {
    padding-right: 0;
  }

  .rk-user-chat-input {
    margin: 0 10px 28px;
    position: absolute;
    bottom: 30px;
  }

  .message-profile-send-item img {
    width: 20px;
  }

  .rk-user-chat-input .message-profile-select-item {
    top: 16px;
    left: 17px;
    width: 25px;
    height: 25px;
  }

  .message-profile-select-item img {
    width: 16px;
  }

  .massage-moment-heading {
    flex-direction: row;
    margin-right: 30px;
  }

  .massage-momemt-map {
    margin: 0 0 30px;
    width: 100%;
  }

  .massage-update-moment {
    margin: 0 auto 0;
  }

  .massage-moment-address {
    padding: 30px 0;
    margin: 30px 0;
    border-width: 0.5px 0;
    width: 100%;
  }

  .massage-moment-profile-details p {
    font-size: 14px;
  }

  .massage-print {
    border-width: 0.1px;
    padding: 22px 20px 22.3px 20px;
  }

  .massage-inbox {
    padding: 23px 17px 24px 20px;
  }

  .massage-details {
    padding: 24px 20px;
    border: 0.5px solid #008ef9;
  }

  .voucher-modal-body {
    padding: 20px;
  }

  .co-login-title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .co-login-form-content {
    padding-top: 40px;
  }

  .co-login-discription {
    max-width: 100%;
    margin: 0 auto 20px;
    font-size: 16px;
    line-height: 26px;
  }

  .confirmed-address {
    padding: 22px 15px;
    width: 100%;
  }

  .confirmed-helth p {
    width: 100%;
  }

  .confirmend-title {
    font-size: 20px;
    line-height: 30px;
  }

  .confirmend-title .massage-moment-subtitle {
    margin-bottom: 0;
  }

  .confirmed-section .order-address-link {
    justify-content: center;
  }

  .confirmed-section .massage-update-edite a {
    font-size: 18px;
    line-height: 24px;
  }

  .confirmed-chat {
    width: 100%;
  }

  .forgot-pass-content {
    margin: 50px auto 0;
    width: 100%;
  }

  .forgot-pass-body {
    padding: 20px 16px;
  }

  .forgot-pass-email {
    margin-bottom: 20px;
  }

  .forgot-pass-heading {
    padding: 11.2px 17px;
  }

  .new-password {
    margin-top: 30px;
  }

  .forgot-pass-option label {
    padding: 20px 15px;
  }

  .forgot-pass-img {
    margin-right: 15px;
  }

  .forgot-pass-option input {
    width: 20px;
    height: 20px;
  }

  .forgot-pass-type h5 {
    font-size: 16px;
    line-height: 22px;
  }

  .location-body {
    padding: 50px 0;
    flex-direction: column;
  }

  .errands-tab-body-title {
    font-size: 22px;
    line-height: 34px;
  }

  .errands-body-address-box {
    padding: 20px;
  }

  .errands-radio-label {
    padding: 9px 46px 9px 10px;
    background-position: right 10px center;
  }

  .errands-radio-label h6,
  .errands-set-location {
    line-height: 23px;
  }

  .massages-header {
    padding: 10.5px 15px;
  }

  .navbar-brand {
    margin-right: 10px;
  }

  .header-dropdown-btn img:last-child {
    width: 25px;
  }

  .header-dropdown-title {
    margin-right: 10px;
  }

  .notification-content {
    width: 100%;
    padding: 40px 0;
  }

  .notification-title {
    font-size: 25px;
    line-height: 29px;
    margin-bottom: 25px;
  }

  .notification-remove-icon {
    margin-left: auto;
  }

  .notification-details {
    margin-right: 20px;
    width: calc(100% - 103px);
  }

  .notification-icon {
    margin-right: 20px;
  }

  .payment-book-logo img {
    margin-right: 5px;
  }

  .payment-book-heading-title {
    font-size: 18px;
    line-height: 24px;
  }

  .payment-method-link {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .payment-method-promo a {
    font-size: 18px;
    line-height: 25px;
  }

  .profile-details-title {
    font-size: 24px;
    line-height: 31px;
  }

  .policy-section {
    padding: 35px 0;
  }

  .view-profile-details-title {
    font-size: 24px;
    line-height: 30px;
  }

  .policy-img {
    margin: 27px 0;
  }

  .policy-rules-title {
    margin-bottom: 20px;
  }

  .policy-rules-country {
    margin: 30px 0;
  }

  .policy-contact {
    max-width: 100%;
  }

  .policy-rules-title {
    font-size: 18px;
  }

  .refer-main-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }

  .refer-discription {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
  }

  .refer-input {
    padding: 14px 20px;
    width: 100%;
    margin-top: 25px;
    flex-direction: column;
    border-radius: 10px;
  }

  .refer-input input {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .refer-input input::placeholder {
    font-size: 16px;
    line-height: 23px;
  }

  .refer-btn {
    width: 100%;
  }

  .refer-work-content {
    margin: 30px 0;
    padding: 30px 0 0;
  }

  .refer-work-title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 25px;
  }

  .refer-work-item {
    width: 100%;
    text-align: center;
  }

  .refer-work-item-no {
    margin: 0 auto 10px;
  }

  .refer-work-item-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .refer-work-discription {
    font-size: 16px;
    line-height: 23px;
    padding: 0 40px;
  }

  .po-sharing-form-box {
    padding: 30px 20px;
    width: 100%;
    margin: 60px auto 0;
  }

  .po-sharing-sidebar {
    width: 100%;
    top: 67px;
    left: -100%;
    padding: 50px 20px 20px;
  }

  .po-sharing-sidebar-show {
    left: 0;
    transition: all 0.5s ease-in-out;
  }

  .profile-account-header .container-fluid {
    padding: 0 20px;
  }

  .po-sharing-form-input {
    margin-bottom: 20px;
  }

  .po-sharing-form-input .form-control {
    font-size: 16px;
    line-height: 23px;
  }

  .po-sharing-form-input .form-control::placeholder {
    font-size: 16px;
    line-height: 23px;
  }

  .po-sharing-form-content {
    padding: 0 15px;
  }

  .po-sharing-profile-img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .po-sharing-profile-title {
    font-size: 23px;
    line-height: 30px;
  }

  .po-sharing-profile-view {
    font-size: 16px;
    line-height: 22px;
  }

  .po-sharing-setting-link,
  .po-sharing-moment a {
    font-size: 16px;
    line-height: 23px;
    padding: 15px;
  }

  .po-sharing-setting-list {
    padding: 20px 0;
  }

  .po-sharing-moment {
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .plusone-profile-img img {
    width: 100px;
  }

  .plusone-profile-img {
    margin-right: 15px;
  }

  .selecting-profile-slider {
    margin: 30px 0;
  }

  .plusone-profile-work {
    padding: 30px 15px;
    margin: 30px 0 30px;
  }

  .profile-work-list {
    display: inherit;
  }

  .profile-work-item {
    margin-bottom: 20px;
  }

  .profile-work-item:last-child {
    margin-bottom: 0;
  }

  .profile-hourly-rate {
    padding: 17px 21px 17px;
  }

  .plusone-profile-price-title h4 {
    font-size: 20px;
    line-height: 29px;
  }

  .hourly-rate-title,
  .plusone-hourly-rate {
    font-size: 16px;
    line-height: 23px;
  }

  .selecting-profile-footer .container {
    flex-direction: column;
  }

  .selecting-profile {
    margin-bottom: 15px;
    border-radius: 50%;
  }

  .selecting-profile {
    margin-bottom: 15px;
  }

  .selecting-profile-price-title {
    font-size: 18px;
    line-height: 29px;
  }

  .price-profile-content {
    padding: 25px 15px;
  }

  .price-profile {
    display: inherit;
  }

  .price-profile-img {
    margin: 0 auto 15px;
  }

  .price-details-price h4 {
    font-size: 20px;
    line-height: 29px;
  }

  .price-details-price img {
    margin-left: 10px;
  }

  .price-details-price img {
    margin-left: 7px;
    width: 9px;
  }

  .view-profile-section {
    padding: 50px 0;
  }

  .view-profile-content {
    display: inherit;
  }

  .view-profile-box {
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  .view-profile-details-content {
    width: 100%;
  }

  .timeset-body .errands-body-address-box {
    padding: 0;
  }

  .datepicker-inline {
    width: 100%;
  }

  .timeset-body {
    padding: 40px 0;
  }

  .timeset-times {
    display: inherit;
  }

  .timeset-time-input {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .timeset-body-heading {
    display: inherit;
  }

  .erratds-caledar-title {
    margin-top: 20px;
  }

  .price-body-finding {
    font-size: 18px;
    line-height: 24px;
  }

  .price-heading-discription {
    font-size: 16px;
    line-height: 25px;
    padding: 9px 0;
  }

  .tetms-interim::before {
    left: 10%;
  }

  .tetms-interim::after {
    right: 10%;
  }

  .terms-discription {
    padding: 24px 15px 24px 15px;
  }

  .price-heading-discription img {
    margin-left: 8px;
  }

  .confirmed-chat .price-profile-img {
    margin: 0 auto 20px;
  }

  .confirmed-chat .price-profile-name {
    justify-content: center;
  }
}

/* ***************** Responsive End  ******************** */

/****** Keyframes Start *******/

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(170deg);
    transform: rotate(170deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(126deg);
    transform: rotate(126deg);
  }
}

/****** Keyframes End *******/

/* Slider Css */
.slick-slide .img-fluid {
  margin: 0 0 !important;
}

.slick-arrow {
  top: 35%;
}

.PhoneInputInput {
  border: none;
  /* border-bottom: 1px solid gray; */
}

.co-phone-input {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px;
  background: #ffffff;

  /* Light Gray - Subheadline */
  border: 0.5px solid #959da3;
  border-radius: 10px;
}

.PhoneInputInput:focus {
  outline: none;
}

/* .Modal Css */
.ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

/* Otp Modal css */
.login-modal-input {
  justify-content: center;
}

input[type="text"]:focus {
  outline: none;
  border: 1px solid black;
}

.otp-field {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  line-height: 4rem;
  text-align: center;
  outline: none;
}

/* Password Show Hide Css */
/* .fa-times{
    opacity: 0;
}
.fa-check{
    opacity: 1;
} */
.text_span {
  font-size: 15px !important;
  margin-left: 45px !important;
}

.pwdhide {
  opacity: 0.5;
}

p .icon {
  position: absolute;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 30px;
  font-size: large;
  border-radius: 50%;
  border: 2px solid rgb(26, 43, 68);
  color: rgb(26, 43, 68);
}

#show_hide {
  color: rgb(26, 43, 68);
  font-size: 20px;
  transform: translateX(460px) translateY(-34px);
}

#show_hide_email {
  color: rgb(26, 43, 68);
  font-size: 20px;
  transform: translateX(310px) translateY(-34px);
}

#show_hide_fp {
  color: rgb(26, 43, 68);
  font-size: 20px;
  transform: translateX(450px) translateY(-34px);
}

.sk-profile {
  width: 105px;
  height: 35px;
  border-radius: 28px;
}

.sk-cat-img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-bottom: 25px;
  margin-top: 5px;
}

.sk-schedule-img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  margin-bottom: 25px;
  margin-top: 5px;
}

.sk-cat-name {
  width: 100px;
  border-radius: 6px;
}

.sk-schedule-name {
  margin-bottom: 10px;
  width: 130px;
  border-radius: 6px;
}

.sk-top-img {
  width: 280px;
  height: 200px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.sk-top-name {
  width: 280px;
  height: 20px;
  border-radius: 2px;
}

.sk-sel-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.sk-sel-name {
  width: 400px;
  height: 40px;
  border-radius: 2px;
  margin-bottom: 10px;
}

.sk-sel-details {
  width: 200px;
  height: 20px;
  margin-top: 10px;
}

.sk-sel-gal {
  width: 106px;
  height: 106px;
}

.sk-sel-slide {
  width: 676px;
}

.sk-sel-about {
  width: 100px;
  height: 20px;
  margin-bottom: 15px;
}

.sk-sel-info {
  width: 500px;
}

.select-profile {
  border-radius: 50%;
  background-color: #d6d6d6;
  border: none;
}

.sk-nav-img {
  height: 70px;
  width: 70px;
  margin: 0 10px;
}

.sk-nav-name {
  width: 150px;
  height: 20px;
  margin: 5px;
}

.prise_main_drop {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #a5a5a5;
  padding: 15px 20px;
  height: auto;
  border-radius: 31px;
  cursor: pointer;
}

.prise-list-merge {
  overflow-y: scroll;
  height: 400px;
}

.prise-list-merge::-webkit-scrollbar {
  display: none;
}

ul.prise-list-merge li {
  -webkit-user-select: none;
  border-radius: 10px;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #414141;
}

ul.prise-list-merge li:hover {
  background: #f6f6f6;
  color: #414141 !important;
}

.prise_main_drop ul.prise-list-merge {
  position: absolute;
  padding: 0;
  width: 100%;
  left: 0;
  right: 0;
  top: 62px;
  margin: 0;
  list-style: none;
  padding: 7px;
  max-height: inherit;
  background-color: #fff;
  box-shadow: 0px 5px 8px rgb(0 0 0 / 15%);
  border-radius: 10px;
  z-index: 99;
  display: none;
}

span.prise_down_icon {
  position: absolute;
  width: 24px;
  height: 24px;
  /* background-image: url(../images/vector_bottom.svg); */
  border: none;
  top: 0;
  left: unset;
  right: 20px;
  background-repeat: no-repeat;
  background-position: center;
  bottom: 0;
  margin: auto;
}

span.prise-data {
  font-weight: 500;
  font-size: 14px;
  padding: 0 0 0 10px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #414141;
}

ul.prise-list-merge li.prise_list.selected {
  background: #fcf5c7 !important;
  color: #8c7c08 !important;
}

.prise_main_drop ul.prise-list-merge.opened {
  display: block;
}

.option-img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
}

.r-book-payment-celebration {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.r-book-celebration-img {
  margin-right: 20px;
}

.r-book-celebration-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #17146b;
  margin-bottom: 5px;
}

.r-book-celebration-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  color: #17146b;
  margin-bottom: 0;
}

.r-book-img {
  height: 70px;
  width: 70px;
}

.mobile-input {
  border: 1px solid gray;
  padding: 10px 20px;
  border-radius: 8px;
}

.refer-input input:focus {
  border: none;
}

/******** Payment New Section Start ********/

.payment-new-title {
  line-height: 34px;
  margin-bottom: 10px;
}

.payment-new-discription {
  line-height: 30px;
  margin-bottom: 20px;
}

.payment-method-new .btn {
  padding: 12px 23px;
  width: 230px;
  max-width: 100%;
}

.payment-method-new {
  margin-bottom: 50px;
}

.payment-method-new .payment-coupons {
  color: #292a2c;
  justify-content: space-between;
  border-top: 1px solid #acb4ba;
  border-bottom: 1px solid #acb4ba;
  padding: 15px 0;
  margin-bottom: 15px;
}

.payment-coupons span {
  font-size: 18px;
}

.payment-new-content .payment-new-term {
  height: 300px;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  padding: 23px 30px 36px;
}

.payment-new-term .privacy-committed-title {
  margin-bottom: 15px;
  margin-top: 15px;
  color: #17146b;
}

.payment-new-card {
  display: flex;
  align-items: center;
  padding: 16.5px 0;
  border-bottom: 0.5px solid #acb4ba;
  margin-bottom: 20px;
}

.payment-new-card a {
  /* border: 0.5px solid #acb4ba; */
  border-radius: 3px;
  padding: 0px 9px;
  margin-right: 24px;
  display: inline-block;
}

.payment-new-card .profile-work-link {
  line-height: 20px;
  color: #292a2c;
  margin-bottom: 0;
}

.payment-new-card span {
  margin-left: auto;
  background-color: #ffffff;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #292a2c;
  padding: 5px 10.5px;
}

.payment-coupon-input input {
  margin-bottom: 20px;
  padding: 13px 20px;
  border-radius: 8px;
}

.payment-coupon-input .btn-warning {
  margin-right: 20px;
}

.payment-coupon-input .privacy-deactivate {
  width: auto;
  padding: 13px 47.6px;
}

.payment-coupon-input {
  display: none;
}

.payment-coupon-input-show {
  display: block;
}

.add-coupon-hide {
  display: none;
}

.payment-no-transfer {
  display: flex;
}

.no-transfer-content {
  margin-left: 30px;
}

.no-transfer-discription {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #646667;
  margin: 0;
}

.privacy-sharing.payment-view-transfer {
  width: 1142px;
  display: inherit;
}

.view-transfer-table {
  margin-top: 13px;
  padding-left: 65px;
}

.view-transfer-img {
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.view-transfer-table thead th {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #17146b;
  border: none;
}

.view-transfer-table thead th:first-child {
  padding-left: 26px;
}

.view-transfer-table thead th {
  padding: 10px 10px 30px;
}

.view-transfer-table tbody tr {
  /* border-radius: 15px; */
}

.view-transfer-table tbody td {
  padding: 0;
  vertical-align: middle;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #646667;
  margin: 15px 0;
  margin: 10px 0;
  border: none;
}

.view-transfer-name {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #17146b;
  margin-left: 20px;
  margin-bottom: 0;
}

.view-transfer-cell {
  border-width: 0.5px 0;
  border-style: solid;
  border-color: #acb4ba;
  padding: 10px;
  height: 80px;
  background-color: #fff;
  margin: 10px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

tbody tr td:nth-child(1) .view-transfer-cell {
  border-left-width: 0.5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

tbody tr td:nth-child(4) .view-transfer-cell {
  border-right-width: 0.5px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* Card Details Modal Start */

.card-details-modal .modal-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  width: 100%;
}

.card-details-modal .btn-close {
  background-image: none;
}

.card-details-modal .modal-content {
  border-radius: 12px;
}

.card-details-modal .modal-header {
  padding: 19px 32px;
  border-bottom: 1px solid #acb4ba;
}

.card-details-modal .modal-dialog {
  max-width: 564px;
}

.card-details-modal .modal-body {
  padding: 50px 32px 20px;
}

.card-details-logos {
  margin-bottom: 20px;
}

.card-details-logos img {
  margin: 0 5px;
}

.card-details-footer {
  justify-content: space-between;
  padding: 14.5px 12px;
}

.card-details-footer .btn {
  margin: 0;
}

.card-details-footer .btn:first-child {
  font-size: 15px;
  text-decoration: underline;
  line-height: 25px;
  color: #292a2c;
}

.card-details-footer .btn:last-child {
  padding: 12px 52.2px;
}

/* Card Details Modal End */
.one-line-text {
  overflow: auto;
}